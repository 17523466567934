import { v1 as uuidv1 } from "uuid";
import { isNil } from "lodash";
import { batch } from "react-redux";

import { modulesResourceName, moduleStudyCasesResourceName, passFailModulesResourceName } from "store/configureResources";
import { getResourceState } from "store/utils";

import { clearResource } from "store/resources/actions/clearResource";
import { useResource } from "store/resources/actions/useResource";
import { createResource } from "store/resources/actions/createResource";
import { updateResource } from "store/resources/actions/updateResource";

import { clearProjectLogItems, clearModelLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";
import { copyModelLogNote } from "store/resources/actions/scenarioLog/modelLogNotesActions";
import { clearCalculationPreconditions } from "store/resources/actions/calculations/calculationsPreconditionsActions";
import { clearInputValidations } from "store/resources/actions/projectInput/projectInputValidationsActions";

import { moduleTypes } from "utils/constants";

import {
    Module,
    ModuleStudyCase,
    UseModulesParams,
    CreateModuleParams,
    UpdateModuleParams,
    PassFailModulesParams,
    DeleteModuleParams,
} from "./types";

export const clearModules =
    ({ idProject, idModel }: UseModulesParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            clearResource({
                resourceName: modulesResourceName,
                key: `${modulesResourceName}-${idProject}-${idModel}`,
                broadcast: true,
            })
        );
    };

export const useModules = ({ idProject, idModel }: UseModulesParams) =>
    useResource({
        resourceName: modulesResourceName,
        key: !isNil(idModel) ? `${modulesResourceName}-${idProject}-${idModel}` : undefined,
        path: {
            entityId: idModel,
        },
    }) as [Module[], boolean | undefined, any];

export const createModule =
    ({ idProject, idModel, idInputLog, studyCaseId, moduleParentId, moduleTemplateId, uiOrder, type }: CreateModuleParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const state = getState();

        const modules = getResourceState<Module>(state, modulesResourceName, { idProject, idModel });
        const moduleStudyCases = getResourceState<ModuleStudyCase>(state, moduleStudyCasesResourceName);

        const studyCaseName = moduleStudyCases.find((studyCase) => studyCase.studyCaseId === studyCaseId)?.uiLabel;

        let moduleLevel = 0;

        if (type === moduleTypes.EE_POTENTIAL) {
            moduleLevel = 1;
        } else if (type === moduleTypes.DR_POTENTIAL) {
            moduleLevel = 2;
        }

        const module = {
            moduleId: uuidv1(),
            moduleTemplateId,
            parentId: moduleParentId,
            studyCaseId,
            moduleLevel,
            moduleOrder: uiOrder?.toString(),
            runState: "PENDING",
            moduleState: "PLANNED",
            studyCase: studyCaseName || "",
            type,
            disableAddPotentialModule: true,
        };

        const updateItems = [...modules];

        // New Baseline Module
        if (type === moduleTypes.BASELINE) {
            // Baseline Module goes at the end of the array
            //
            // @ts-ignore - specific case only used for optimistic update
            updateItems.push(module);
        } else {
            // If parent Module has children,
            // then find by parentId.
            // If parent Module does not have children,
            // then find by moduleId
            let insertIndex = updateItems.findLastIndex(
                (module) => module.parentId === moduleParentId || module.moduleId === moduleParentId
            );

            // Number of children for the parent (Baseline || EE Potential) Module
            const length = updateItems.filter((module) => module.parentId === moduleParentId).length;

            // New EE Potential Module
            if (type === moduleTypes.EE_POTENTIAL) {
                const { moduleId } = updateItems[insertIndex];

                // Number of children for the parent EE Potential Module
                const childrenLength = updateItems.filter((module) => module.parentId === moduleId).length;

                insertIndex = insertIndex + 1 + childrenLength;

                module.moduleOrder = `${module.moduleOrder}.${length + 1}`;
            }
            // New DR Potential Module
            else {
                insertIndex++;

                const moduleOrder = updateItems.find((module) => module.moduleId === moduleParentId)?.moduleOrder;

                module.moduleOrder = `${moduleOrder}.${length + 1}`;
            }

            // EE Potential and DR Potential Modules can go anywhere
            // in the array
            //
            // @ts-ignore - specific case only used for optimistic update
            updateItems.splice(insertIndex, 0, module);
        }

        dispatch(
            createResource({
                resourceName: modulesResourceName,
                key: `${modulesResourceName}-${idProject}-${idModel}`,
                path: {
                    entityId: idModel,
                },
                query: {
                    studyCaseLookupId: studyCaseId,
                    moduleParentId,
                    moduleTemplateId,
                    type,
                },
                optimisticUpdate:
                    updateItems?.length > 0
                        ? {
                              value: updateItems,
                          }
                        : undefined,
                onComplete: () => {
                    batch(() => {
                        dispatch(clearModules({ idProject, idModel }));
                        dispatch(clearCalculationPreconditions({ idProject }));
                        dispatch(clearInputValidations({ idInputLog }));
                    });
                },
            })
        );
    };

export const updateModule =
    ({
        idProject,
        idModel,
        idInputLog,
        moduleId,
        studyCaseId,
        moduleParentId,
        uiOrder,
        runState,
        moduleState,
        markedForDeletion,
        onSuccess,
        onComplete,
    }: UpdateModuleParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const state = getState();

        const modules = getResourceState<Module>(state, modulesResourceName, { idProject, idModel });

        let updateItems: Module[] = [];

        if (markedForDeletion) {
            updateItems = modules.filter((module) => module.moduleId !== moduleId);
        } else {
            const moduleStudyCases = getResourceState<ModuleStudyCase>(getState, moduleStudyCasesResourceName);

            const studyCaseName = moduleStudyCases.find((studyCase) => studyCase.studyCaseId === studyCaseId)?.uiLabel;

            updateItems = modules.map((module) =>
                module.moduleId === moduleId
                    ? {
                          ...module,
                          studyCaseId: studyCaseId || module.studyCaseId,
                          studyCase: studyCaseName || module.studyCase,
                          moduleState: moduleState || module.moduleState,
                      }
                    : module
            );
        }

        dispatch(
            updateResource({
                resourceName: modulesResourceName,
                key: `${modulesResourceName}-${idProject}-${idModel}`,
                path: {
                    entityId: moduleId,
                },
                query: {
                    studyCaseLookupId: studyCaseId,
                    moduleParentId,
                    uiOrder,
                    runState,
                    moduleState,
                    markedForDeletion,
                },
                optimisticUpdate:
                    updateItems.length > 0
                        ? {
                              value: updateItems,
                          }
                        : undefined,
                onSuccess: () => {
                    batch(() => {
                        dispatch(clearModules({ idProject, idModel }));
                        dispatch(clearCalculationPreconditions({ idProject }));
                        dispatch(clearInputValidations({ idInputLog }));

                        onSuccess?.();
                    });
                },
                onError: () => {
                    dispatch(clearModules({ idProject, idModel }));
                },
                onComplete,
            })
        );
    };

export const passFailModules =
    ({ idProject, idInputLog, idModel, action, userNote, onSuccess }: PassFailModulesParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            updateResource({
                resourceName: passFailModulesResourceName,
                query: {
                    modelId: idModel,
                    action,
                },
                onSuccess: () => {
                    batch(() => {
                        dispatch(clearModules({ idProject, idModel }));
                        dispatch(clearProjectLogItems({ idProject }));
                        dispatch(clearModelLogItems({ idProject, idModel }));

                        userNote && dispatch(copyModelLogNote({ idProject, idInputLog, idModel, userNote, isPublic: true }));
                    });

                    onSuccess?.();
                },
            })
        );
    };

export const deleteModule =
    ({ idProject, idModel, idInputLog, moduleId, onSuccess }: DeleteModuleParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            updateModule({
                idProject,
                idModel,
                idInputLog,
                moduleId,
                markedForDeletion: true,
                onSuccess,
            })
        );
    };
