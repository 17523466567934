import { combineReducers } from "redux";

import { USER_DELETE } from "./actionTypes";

import { reducer as resourcesReducer } from "./resources/reducer";

import { reducer as loginReducer } from "./login/reducer";
import { reducer as changePasswordReducer } from "./login/changePassword/reducer";
import { reducer as resetPasswordReducer } from "./login/resetPassword/reducer";

import { reducer as avoidedCostsReducer } from "./avoidedCosts/reducer";
import { reducer as clientsReducer } from "./clients/reducer";
import { reducer as downloadFileReducer } from "./downloadFile/reducer";
import { reducer as modalReducer } from "./modal/reducer";
import { reducer as modelDataImportReducer } from "./modelDataImport/reducer";
import { reducer as modulesReducer } from "./modules/reducer";
import { reducer as powerBiRefreshActionReducer } from "./powerBI/refreshAction/reducer";
import { reducer as powerBiRefreshStatusReducer } from "./powerBI/refreshStatus/reducer";
import { reducer as projectionsReducer } from "./projections/reducer";
import { reducer as runCalculationReducer } from "./runCalculation/reducer";
import { reducer as runModuleReducer } from "./runModule/reducer";
import { reducer as sideNavReducer } from "./sideNav/reducer";
import { reducer as systemReducer } from "./system/reducer";
import { reducer as userReducer } from "./user/reducer";
import { reducer as userProfileReducer } from "./userProfile/reducer";
import { reducer as vdsmUserReducer } from "./vdsmUser/reducer";
import { reducer as windowReducer } from "./window/reducer";

const reducers = {
    resources: resourcesReducer,

    login: loginReducer,
    changePassword: changePasswordReducer,
    resetPassword: resetPasswordReducer,

    avoidedCosts: avoidedCostsReducer,
    clients: clientsReducer,
    downloadFile: downloadFileReducer,
    modal: modalReducer,
    modelDataImport: modelDataImportReducer,
    modules: modulesReducer,
    powerBiRefreshAction: powerBiRefreshActionReducer,
    powerBiRefreshStatus: powerBiRefreshStatusReducer,
    projections: projectionsReducer,
    runCalculation: runCalculationReducer,
    runModule: runModuleReducer,
    sideNav: sideNavReducer,
    system: systemReducer,
    user: userReducer,
    userProfile: userProfileReducer,
    vdsmUser: vdsmUserReducer,
    window: windowReducer,
};

const appReducer = combineReducers({
    ...reducers,
});

const rootReducer = (state, action) => {
    if (action.type === USER_DELETE) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default rootReducer;
