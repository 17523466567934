import { batch } from "react-redux";

import {
    PAUSE_MODULE_STARTED,
    RUN_ALL_MODULES_STARTED,
    RUN_MODULE_STARTED,
    RUN_MODULE_FAILED,
    RUN_MODULE_COMPLETE,
} from "store/actionTypes";
import { moduleAllRunsResourceName } from "store/configureResources";

import { getResourcePromise } from "store/resources/actions/getResource";

import { createRunAllModules, createRunModule } from "store/resources/actions/calculationWorkflow/runModuleActions";
import { updatePauseModule } from "store/resources/actions/calculationWorkflow/pauseModuleActions";
import { clearModules } from "store/resources/actions/calculationWorkflow/modulesActions";
import { clearAllModuleRuns, clearLastModuleRun } from "store/resources/actions/calculationWorkflow/moduleRunsActions";
import { clearModuleInputs } from "store/resources/actions/calculationWorkflow/moduleInputsActions";
import { clearCalculations } from "store/resources/actions/calculations/calculationsActions";
import { clearProjectLogItems, clearModelLogItems } from "store/resources/actions/scenarioLog/scenarioLogActions";

import { startRunCalculationStatusCheck } from "store/processes/actions/runCalculation/runCalculationActions";

import {
    CheckAllModulesRunStatusParams,
    CheckModuleRunStatusParams,
    PauseModuleStartPayload,
    RunAllModulesStartPayload,
    RunModuleStartPayload,
} from "./types";
import { ModuleRun } from "store/resources/actions/calculationWorkflow/types";

const STATUS_CHECK_TIMEOUT = 5000;

const timeout: Record<number, number> = {};

export const checkModuleRunStatus =
    ({ idProject, idModel, moduleId, runId }: CheckModuleRunStatusParams) =>
    // @ts-ignore
    async (dispatch) => {
        const allModuleRuns = (await getResourcePromise({
            resourceName: moduleAllRunsResourceName,
            key: `${moduleAllRunsResourceName}-${moduleId}`,
            path: {
                moduleId,
            },
        })) as ModuleRun[];

        const currentRun = allModuleRuns.find((moduleRun) => moduleRun.modelRunId === runId);

        if (currentRun) {
            // Keep checking status if run is in progress
            if (currentRun.status === "IN_PROGRESS") {
                clearTimeout(timeout[moduleId]);

                timeout[moduleId] = window.setTimeout(() => {
                    dispatch(checkModuleRunStatus({ idProject, idModel, moduleId, runId }));
                }, STATUS_CHECK_TIMEOUT);

                dispatch({
                    type: RUN_MODULE_STARTED,
                    payload: {
                        idModel,
                        moduleId,
                        status: currentRun.status,
                    },
                });
            }
            // If run has an error, stop checking status
            // and set state to error
            else if (currentRun.status === "ERROR") {
                clearTimeout(timeout[moduleId]);

                batch(() => {
                    dispatch({
                        type: RUN_MODULE_FAILED,
                        payload: {
                            idModel,
                            moduleId,
                            status: currentRun.status,
                        },
                    });

                    dispatch(clearProjectLogItems({ idProject }));
                    dispatch(clearModelLogItems({ idProject, idModel }));
                    dispatch(clearModuleInputs({ moduleId }));
                    dispatch(clearModules({ idProject, idModel }));
                    dispatch(clearLastModuleRun({ moduleId }));
                });
            }
            // If run is finished, stop checking status
            // and set state to done/terminated
            else if (["DONE", "TERMINATED"].includes(currentRun.status)) {
                clearTimeout(timeout[moduleId]);

                batch(() => {
                    dispatch({
                        type: RUN_MODULE_COMPLETE,
                        payload: {
                            idModel,
                            moduleId,
                            status: currentRun.status,
                        },
                    });

                    dispatch(clearProjectLogItems({ idProject }));
                    dispatch(clearModelLogItems({ idProject, idModel }));
                    dispatch(clearModuleInputs({ moduleId }));
                    dispatch(clearModules({ idProject, idModel }));
                    dispatch(clearLastModuleRun({ moduleId }));
                });
            }
        } else {
            clearTimeout(timeout[moduleId]);
        }
    };

export const checkAllModulesRunStatus =
    ({ idProject, idModel, runList }: CheckAllModulesRunStatusParams) =>
    // @ts-ignore
    async (dispatch) => {
        runList.forEach(({ id, modelId }) =>
            dispatch(checkModuleRunStatus({ idProject, idModel, moduleId: Number(modelId), runId: Number(id) }))
        );
    };

export const runAllModules =
    ({ idProject, idModel, runList, onStart, onError }: RunAllModulesStartPayload) =>
    // @ts-ignore
    (dispatch) => {
        const onRunAllModuleSuccess = (action: any) => {
            batch(() => {
                dispatch({
                    type: RUN_ALL_MODULES_STARTED,
                    payload: {
                        idProject,
                        idModel,
                        runList,
                    },
                });

                dispatch(checkAllModulesRunStatus({ idProject, idModel, runList: action?.data || [] }));

                dispatch(clearCalculations());

                dispatch(clearModules({ idProject, idModel }));
            });

            onStart?.();
        };

        dispatch(
            createRunAllModules({
                idModel,
                runList,
                onSuccess: onRunAllModuleSuccess,
                onError,
            })
        );
    };

export const runModule =
    ({ idProject, idModel, moduleId, onStart, onError }: RunModuleStartPayload) =>
    // @ts-ignore
    (dispatch) => {
        const onRunModuleSuccess = (action: any) => {
            const { id, status } = action?.data;

            batch(() => {
                dispatch({
                    type: RUN_MODULE_STARTED,
                    payload: {
                        idModel,
                        moduleId,
                        status,
                    },
                });

                dispatch(startRunCalculationStatusCheck());

                dispatch(checkModuleRunStatus({ idProject, idModel, moduleId, runId: Number(id) }));

                dispatch(clearModules({ idProject, idModel }));
                dispatch(clearAllModuleRuns({ moduleId }));
            });

            onStart?.();
        };

        dispatch(
            createRunModule({
                moduleId,
                onSuccess: onRunModuleSuccess,
                onError,
            })
        );
    };

export const pauseModule =
    ({ idModel, moduleId, resultsId }: PauseModuleStartPayload) =>
    // @ts-ignore
    (dispatch) => {
        const onPauseModuleSuccess = () => {
            batch(() => {
                dispatch({
                    type: PAUSE_MODULE_STARTED,
                    payload: {
                        idModel,
                        moduleId,
                        status: "PAUSED",
                    },
                });
            });
        };

        dispatch(
            updatePauseModule({
                moduleId,
                resultsId,
                onSuccess: onPauseModuleSuccess,
            })
        );
    };
