import cn from "classnames";
import { get, isEmpty, isNil, kebabCase } from "lodash";
import { memo, useCallback, useEffect, useMemo } from "react";
import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import { RUN_MODULE_START, PAUSE_MODULE_START } from "store/actionTypes";

import { checkModuleRunStatus } from "store/runModule/actions";

import { updateModule } from "store/resources/actions/calculationWorkflow/modulesActions";
import { useAllModuleRuns, useLastModuleRun, clearLastModuleRun } from "store/resources/actions/calculationWorkflow/moduleRunsActions";

import Button from "components/ui/ButtonNew";
import TagWithDropdown from "components/ui/Dropdown/TagWithDropdown";
import Icon from "components/ui/IconNew";
import IconLoading from "components/ui/Icons/IconLoading";
import { Card } from "components/ui/Cards";
import Label from "components/ui/Label";
import Link from "components/ui/Link";
import { StaticProgressBar } from "components/ui/ProgressBar";
import StepBox from "components/ui/StepBox";
import Tag from "components/ui/Tag";

import { openModalDialogClientReviewModule, openModalDialogSetInClientReviewError } from "layouts/Modal/CalculationWorkflowModal/utils";

import { actionAllowed } from "pages/utils";

import { useHaveRights } from "utils/useHaveRights";
import { useUserRights } from "utils/useUserRights";
import { allComponentModelStates, allModuleStates, moduleTypeLabels, moduleRunState, moduleStates } from "utils/constants";
import { d } from "utils/date";
import { toLocaleDateTime } from "utils/dateTime";
import { hasAnyOfPermissions, hasInternalUserRole } from "utils/user";
import { USER_ACTIONS, USER_ROLES } from "utils/user/defines";
import { openWindowSingleModelReport } from "utils/window";

import { RunState, RunStatus, ModuleState } from "store/resources/actions/calculationWorkflow/types";
import { ModuleCardProps } from "./types";

import "./style.scss";

const ModuleCard = memo(
    ({
        dataTestId,
        idClient,
        idProject,
        idAnalyst,
        idReviewer,
        idModel,
        idInputLog,
        moduleId,
        moduleLevel,
        moduleOrder,
        moduleState,
        runState,
        studyCase,
        type,
        modelName,
        modelState,
        modelRequired,
        selected,
        onClick,
    }: ModuleCardProps) => {
        const dispatch = useDispatch();

        // @ts-ignore
        const userName = useSelector((state) => state.user.userName);

        const runStatus = useSelector((state) => get(state, `runModule.${idModel}-${moduleId}`)) as never;

        // Resources

        const [allModuleRuns, isLoadingAllModuleRuns] = useAllModuleRuns({ moduleId: typeof moduleId === "number" ? moduleId : undefined });

        const cardClassNames = cn("module-card", {
            "module-card-secondary": moduleLevel === 1,
            "module-card-tertiary": moduleLevel === 2,
        });

        const latestRun = allModuleRuns && allModuleRuns[0];

        const [lastModuleRun, isLoadingLastModuleRun] = useLastModuleRun({
            moduleId: latestRun?.status === "IN_PROGRESS" ? moduleId : undefined,
        });

        let tagIcon = "";

        if (runState === "DATA_MISSING" || (runState === "ERROR" && runStatus !== "IN_PROGRESS")) {
            tagIcon = "text-alert__error_b_f";
        } else if (runState === "RECALC_PENDING" && runStatus !== "IN_PROGRESS") {
            tagIcon = "text-warning__report_problem_b_f";
        }

        const userRights = useUserRights();

        const haveAnalystRights = useHaveRights({ entityId: idAnalyst, userName });
        const haveReviewerRights = useHaveRights({ entityId: idReviewer, userName });

        const modelReadOnlyEnabled = modelState === allComponentModelStates.FINAL.toUpperCase() && Boolean(modelRequired) === true;

        // Effects

        useEffect(() => {
            if (!isNil(latestRun)) {
                // Start checking Module Run status if latest run
                // status is in progress and runModule state is undefined.
                // This use case is for users that open Calculation Workflow page
                // when Module Run is already in progress
                if (latestRun.status === "IN_PROGRESS" && runStatus === undefined) {
                    dispatch(checkModuleRunStatus({ idProject, idModel, moduleId, runId: latestRun.modelRunId }));
                }

                // Check the calculation percentage if calculation status is in progress
                if (latestRun.status === "IN_PROGRESS" && runStatus === "IN_PROGRESS") {
                    dispatch(clearLastModuleRun({ moduleId }));
                }
            }
        }, [idProject, idModel, moduleId, runStatus, latestRun, dispatch]);

        // Memos

        const moduleName = useMemo(() => `${moduleTypeLabels[type]} | ${studyCase}`, [type, studyCase]);

        const moduleStateItems = useMemo(() => {
            let moduleStateItems: any[] = [];

            if (haveAnalystRights && moduleStates[USER_ROLES.ANALYST].hasOwnProperty(moduleState.toUpperCase())) {
                moduleStateItems = moduleStates[USER_ROLES.ANALYST][moduleState.toUpperCase()].map((status) => ({
                    label: status,
                    value: status.toUpperCase(),
                }));
            }
            if (haveReviewerRights && moduleStates[USER_ROLES.MANAGER].hasOwnProperty(moduleState.toUpperCase())) {
                moduleStateItems = moduleStates[USER_ROLES.MANAGER][moduleState.toUpperCase()].map((status) => ({
                    label: status,
                    value: status.toUpperCase(),
                }));
            }

            return moduleStateItems;
        }, [moduleState, haveAnalystRights, haveReviewerRights]);

        const calculationProgress = useMemo(() => {
            let calculationProgress = 0;

            if (!isEmpty(lastModuleRun) && !isLoadingLastModuleRun) {
                const total = lastModuleRun.length;
                const done = lastModuleRun.filter((moduleRun) => moduleRun.status === "DONE").length;

                calculationProgress = Math.floor((done / total) * 100);
            }

            return calculationProgress;
        }, [lastModuleRun, isLoadingLastModuleRun]);

        const changeModuleStatus = useCallback(
            (moduleStatus: ModuleState) => {
                dispatch(
                    updateModule({
                        idProject,
                        idModel,
                        idInputLog,
                        moduleId,
                        moduleState: moduleStatus,
                    })
                );
            },
            [idProject, idModel, idInputLog, moduleId, dispatch]
        );

        const onResultClick = useCallback(
            (resultsId, finished) => {
                dispatch(
                    openWindowSingleModelReport({
                        idClient,
                        idProject,
                        idInputLog,
                        idModel,
                        filterEntityId: resultsId,
                        title: modelName,
                        subTitle: `${studyCase.toUpperCase()} - ${toLocaleDateTime(`${finished}Z`)}`,
                        showApprovement:
                            moduleState === allModuleStates.IN_CLIENT_REVIEW.toUpperCase() &&
                            hasAnyOfPermissions([USER_ACTIONS.MODULES_APPROVE]),
                    })
                );
            },
            [idClient, idProject, idInputLog, idModel, studyCase, modelName, moduleState, dispatch]
        );

        const onModuleStatusChange = useCallback(
            (moduleName, moduleStatus) => {
                if (moduleStatus === allModuleStates.IN_CLIENT_REVIEW.toUpperCase()) {
                    // If Model is set to In Progress,
                    // Modal window with a message pops up
                    // that user has to confirm
                    if (modelState === allComponentModelStates.IN_PROGRESS.toUpperCase()) {
                        dispatch(
                            openModalDialogClientReviewModule({
                                moduleName,
                                onChange: () => changeModuleStatus(moduleStatus),
                            })
                        );
                    }
                    // If Model is not set to In Progress,
                    // Modal window with an error pops up
                    else {
                        dispatch(
                            openModalDialogSetInClientReviewError({
                                modelName,
                                moduleName,
                            })
                        );
                    }
                } else {
                    changeModuleStatus(moduleStatus);
                }
            },
            [modelName, modelState, changeModuleStatus, dispatch]
        );

        const renderButton = useCallback(
            (currentState: RunState, currentStatus?: RunState & RunStatus) => {
                const onRunClick = (event: React.MouseEvent<HTMLIdsButtonElement, MouseEvent>) => {
                    event.stopPropagation();

                    dispatch({
                        type: RUN_MODULE_START,
                        payload: {
                            idProject,
                            idModel,
                            moduleId,
                        },
                    });
                };

                const onPauseClick = (event: React.MouseEvent<HTMLIdsButtonElement, MouseEvent>, resultsId: number) => {
                    event.stopPropagation();

                    dispatch({
                        type: PAUSE_MODULE_START,
                        payload: {
                            idProject,
                            idModel,
                            moduleId,
                            resultsId,
                            status: PAUSE_MODULE_START,
                        },
                    });
                };

                const renderRunButton = (title: string) => (
                    <Button
                        dataTestId="run-module"
                        variant="tertiary"
                        padding="md"
                        iconLeft="multimedia-play__start_b_s"
                        iconSize="sm"
                        isDisabled={currentState === "DATA_MISSING"}
                        onClick={onRunClick}
                    >
                        <>{title}</>
                    </Button>
                );

                const renderPauseButton = (title: string) => (
                    <Button
                        dataTestId="pause-module"
                        variant="tertiary"
                        padding="md"
                        iconLeft="multimedia-pause_b_a"
                        iconSize="sm"
                        isDisabled={
                            currentStatus === undefined ||
                            ["PENDING", "RECALC_PENDING", "ERROR"].includes(currentState) ||
                            ["RUN_MODULE_START", "PAUSE_MODULE_START", "PAUSE_MODULE_STARTED"].includes(String(currentStatus))
                        }
                        onClick={isNil(latestRun) ? undefined : (event) => onPauseClick(event, latestRun.resultsId)}
                    >
                        <>{title}</>
                    </Button>
                );

                /* eslint-disable no-fallthrough */
                switch (String(currentStatus)) {
                    // only running module can be stopped:
                    case "RUN_MODULE_STARTED":
                    case "IN_PROGRESS":
                    case "RUNNING":
                    // pause button is disabled below:
                    case "RUN_MODULE_START":
                    case "PAUSE_MODULE_START":
                    case "PAUSE_MODULE_STARTED":
                        return renderPauseButton("Pause");
                    case "PAUSED":
                        return renderRunButton("Re-calculate");
                    default:
                        switch (currentState) {
                            case "RUNNING":
                                return renderPauseButton("Pause");
                            case "PENDING":
                            case "CALCULATED":
                                return renderRunButton("Calculate");
                            case "ERROR":
                            case "RECALC_PENDING":
                                return renderRunButton("Re-calculate");
                            default:
                                return null;
                        }
                }
            },
            [idProject, idModel, moduleId, latestRun, dispatch]
        );

        return (
            <Card
                cardClassName={cardClassNames}
                contentClassName="flex-row align-center"
                selected={selected}
                status={runState}
                roundBorders
                onClick={onClick}
            >
                <div className="module-card__drag-and-drop-icon-container">
                    <Icon icon="ui-menu__hamburger_b_a" clickable />
                </div>
                {isLoadingAllModuleRuns ? (
                    <IconLoading />
                ) : (
                    <div data-testid={dataTestId} className="flex-row flex-one-in-row align-center">
                        <div className="flex-column">
                            <div className="flex-row align-center">
                                <StepBox number={moduleOrder} />
                                <span className="module-card__module-name" title={`ID: ${moduleId}`}>
                                    {moduleName}
                                </span>
                                <span>
                                    <Tag
                                        dataTestId="calculation-status"
                                        className="module-card__calculation-status"
                                        variant={moduleRunState[String(runStatus)]?.variant ?? moduleRunState[runState]?.variant}
                                        size="sm"
                                        iconLeft={tagIcon}
                                        iconSize="sm"
                                    >
                                        <>{moduleRunState[String(runStatus)]?.label ?? moduleRunState[runState]?.label}</>
                                    </Tag>
                                </span>
                            </div>
                            {(latestRun?.status === "IN_PROGRESS" || runStatus === "IN_PROGRESS") && (
                                <div className="flex-row module-card__bottom-container">
                                    <span className="module-card__progress-bar">
                                        <StaticProgressBar percentage={calculationProgress} />
                                    </span>
                                    <span className="module-card__progress-label">{`${calculationProgress}%`}</span>
                                </div>
                            )}
                            {latestRun?.status === "DONE" && runStatus !== "IN_PROGRESS" && (
                                <div className="module-card__bottom-container">
                                    <span>
                                        <Link
                                            className="module-card__link"
                                            onClick={() => onResultClick(latestRun.resultsId, latestRun.finished)}
                                        >
                                            Last calculation result
                                        </Link>
                                    </span>
                                    <span>:</span>
                                    <span className="module-card__date">{d.format(new Date(latestRun.finished + "Z"))}</span>
                                </div>
                            )}
                        </div>
                        <div className="flex-row align-center flex-one-in-row module-card__right-container">
                            {(modelRequired || hasInternalUserRole()) && (
                                <div className="flex-row align-center justify-end module-card__module-state-container">
                                    <Label>Status</Label>
                                    {!isEmpty(moduleStateItems) && !modelReadOnlyEnabled && (haveAnalystRights || haveReviewerRights) ? (
                                        <>
                                            <TagWithDropdown
                                                tagClassName={kebabCase(moduleState)}
                                                value={moduleState}
                                                items={moduleStateItems}
                                                onChange={(value) => onModuleStatusChange(moduleName, value)}
                                            />
                                        </>
                                    ) : (
                                        <div className="aeg-tag-wrapper">
                                            <Tag className={kebabCase(moduleState)} size="sm">
                                                <>{moduleState.toLowerCase()}</>
                                            </Tag>
                                        </div>
                                    )}
                                </div>
                            )}
                            {actionAllowed(userRights, USER_ACTIONS.CALCULATION_WORKFLOW_RUN, modelRequired) && (
                                <div className="module-card__calculate-button-container">{renderButton(runState, runStatus)}</div>
                            )}
                        </div>
                    </div>
                )}
            </Card>
        );
    }
);

export default ModuleCard;
