import queryString from "query-string";

import { isResponseFileDownload, isResponseJson, isResponseText, isResponseNoContent } from "./functions";

import * as _actionTypes from "store/actionTypes";

import { refresh } from "store/login/actions";
import { reportError } from "store/error/actions";

import { createHttpError } from "utils/error";
import { isQrCode, tokenExpired } from "utils/user";

const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
};

const getRequestParams = {
    method: "GET",
    mode: "cors",
    headers: headers,
};

const postRequestParams = {
    method: "POST",
    mode: "cors",
    headers: headers,
};

const putRequestParams = {
    method: "PUT",
    mode: "cors",
    headers: headers,
};

const deleteRequestParams = {
    method: "DELETE",
    mode: "cors",
    headers: headers,
};

export function apiMiddleware({ dispatch, getState }) {
    return (next) => (action) => {
        var actionFunc = next;

        switch (action.type) {
            case _actionTypes.API_GET:
                actionFunc = apiGet;

                break;

            case _actionTypes.API_VDSM_GET_AUTHORIZED:
                actionFunc = apiVdsmGetAuthorized;

                break;

            case _actionTypes.API_GET_AUTHORIZED:
                actionFunc = apiGetAuthorized;

                break;

            case _actionTypes.API_POST:
                actionFunc = apiPost;

                break;

            case _actionTypes.API_VDSM_POST_AUTHORIZED:
                actionFunc = apiVdsmPostAuthorized;

                break;

            case _actionTypes.API_POST_AUTHORIZED:
                actionFunc = apiPostAuthorized;

                break;

            case _actionTypes.API_PUT:
                actionFunc = apiPut;

                break;

            case _actionTypes.API_PUT_AUTHORIZED:
                actionFunc = apiPutAuthorized;

                break;

            case _actionTypes.API_DELETE:
                actionFunc = apiDelete;

                break;

            case _actionTypes.API_DELETE_AUTHORIZED:
                actionFunc = apiDeleteAuthorized;

                break;

            default:
                actionFunc = next;

                break;
        }

        return actionFunc(action);
    };

    async function apiGet(action, requestParams = getRequestParams) {
        const { url, query, actionTypes, passThroughData } = action;

        let queryParams = "";
        if (query) {
            queryParams = getQueryString(query);
        }

        try {
            actionTypes && actionTypes.pending && dispatch({ type: actionTypes.pending, passThroughData: passThroughData });

            const response = await fetch(url + queryParams, requestParams);
            handleHttpResponse(response, action);
        } catch (e) {
            handleNetworkError(e, action);
        }
    }

    async function apiGetAuthorized(action) {
        const requestParams = {
            method: "GET",
            headers: getAuthorizedHeaders(),
        };

        if (needToRefreshToken()) {
            refresh({ action })(dispatch, getState);
            return;
        }

        apiGet(action, requestParams);
    }

    async function apiVdsmGetAuthorized(action) {
        const requestParams = {
            method: "GET",
            headers: getVdsmAuthorizedHeaders(),
        };

        if (needToRefreshToken()) {
            refresh({ action })(dispatch, getState);
            return;
        }

        apiGet(action, requestParams);
    }

    async function apiPost(action, requestParams = postRequestParams) {
        const { url, headers, query, body, actionTypes, passThroughData } = action;

        if (headers) {
            requestParams.headers = {
                ...requestParams.headers,
                ...headers,
            };
        }

        let queryParams = "";
        if (query) {
            queryParams = getQueryString(query);
        }

        if (body) {
            requestParams.body = body;
            if (body instanceof FormData) {
                const { "Content-Type": _remove, ...result } = requestParams.headers;

                requestParams.headers = result;
            }
        }

        try {
            actionTypes && actionTypes.pending && dispatch({ type: actionTypes.pending, passThroughData: passThroughData });

            const xhr = new XMLHttpRequest();
            xhr.open("POST", url + queryParams, true);

            Object.keys(requestParams.headers).forEach((key) => {
                xhr.setRequestHeader(key, requestParams.headers[key]);
            });
            // xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

            xhr.addEventListener("error", (e) => {
                handleNetworkError(e, action);
            });

            let prevPercent = 0;

            xhr.upload.addEventListener("progress", (e) => {
                const percent = ((e.loaded * 100.0) / e.total)?.toFixed(0) || 0;
                if (~~(prevPercent / 5) !== ~~(percent / 5)) {
                    handleUploadProgress({ percent }, action);
                    prevPercent = percent;
                }
            });

            xhr.upload.addEventListener("loadstart", (e) => {
                handleUploadStart(action);
            });

            function parseHeaders(rawHeaders) {
                var headers = new Headers();
                // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
                // https://tools.ietf.org/html/rfc7230#section-3.2
                var preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, " ");
                preProcessedHeaders.split(/\r?\n/).forEach(function (line) {
                    var parts = line.split(":");
                    var key = parts.shift().trim();
                    if (key) {
                        var value = parts.join(":").trim();
                        headers.append(key, value);
                    }
                });
                return headers;
            }

            xhr.addEventListener("readystatechange", (e) => {
                if (4 === xhr.readyState && xhr.status > 0) {
                    var options = {
                        status: xhr.status,
                        statusText: xhr.statusText,
                        headers: parseHeaders(xhr.getAllResponseHeaders() || ""),
                    };
                    options.url = "responseURL" in xhr ? xhr.responseURL : options.headers.get("X-Request-URL");
                    var body = "response" in xhr ? xhr.response : xhr.responseText;

                    handleHttpResponse(new Response(body, options), action);
                }
            });

            handleUploadProgress({ percent: 0 }, action);

            xhr.send(body);

            // const response = await fetch(url + queryParams, requestParams);
            // handleHttpResponse(response, action);
        } catch (e) {
            handleNetworkError(e, action);
        }
    }

    async function apiVdsmPostAuthorized(action) {
        const requestParams = {
            method: "POST",
            headers: getVdsmAuthorizedHeaders(),
        };

        if (needToRefreshToken()) {
            refresh({ action })(dispatch, getState);
            return;
        }

        apiPost(action, requestParams);
    }

    async function apiPostAuthorized(action) {
        const requestParams = {
            method: "POST",
            headers: getAuthorizedHeaders(),
        };

        if (needToRefreshToken()) {
            refresh({ action })(dispatch, getState);
            return;
        }

        apiPost(action, requestParams);
    }

    async function apiPut(action, requestParams = putRequestParams) {
        const { url, query, body, actionTypes, passThroughData } = action;

        let queryParams = "";

        if (query) {
            queryParams = getQueryString(query);
        }

        if (body) {
            requestParams.body = body;

            if (body instanceof FormData) {
                const { "Content-Type": _remove, ...result } = requestParams.headers;

                requestParams.headers = result;
            }
        }

        try {
            actionTypes && actionTypes.pending && dispatch({ type: actionTypes.pending, passThroughData: passThroughData });

            const response = await fetch(url + queryParams, requestParams);

            handleHttpResponse(response, action);
        } catch (e) {
            handleNetworkError(e, action);
        }
    }

    async function apiPutAuthorized(action) {
        const requestParams = {
            method: "PUT",
            headers: getVdsmAuthorizedHeaders(),
        };

        if (needToRefreshToken()) {
            refresh({ action })(dispatch, getState);
            return;
        }

        apiPut(action, requestParams);
    }

    async function apiDelete(action, requestParams = deleteRequestParams) {
        const { url, query, body, actionTypes, passThroughData } = action;

        let queryParams = "";
        if (query) {
            queryParams = getQueryString(query);
        }

        if (body) {
            requestParams.body = body;
        }

        try {
            actionTypes && actionTypes.pending && dispatch({ type: actionTypes.pending, passThroughData: passThroughData });

            const response = await fetch(url + queryParams, requestParams);
            handleHttpResponse(response, action);
        } catch (e) {
            handleNetworkError(e, action);
        }
    }

    async function apiDeleteAuthorized(action) {
        const requestParams = {
            method: "DELETE",
            headers: getVdsmAuthorizedHeaders(),
        };

        if (needToRefreshToken()) {
            refresh({ action })(dispatch, getState);
            return;
        }

        apiDelete(action, requestParams);
    }

    async function handleHttpResponse(response, action) {
        const { actionTypes, responseActionType, passThroughData } = action;

        if (isTokenExpired(response)) {
            if (actionTypes && actionTypes.error) {
                dispatch({
                    type: actionTypes.error,
                    message: null,
                    passThroughData,
                });
            }

            refresh({ action, dispatchError: true })(dispatch, getState);

            return;
        }

        if (response.ok) {
            let data = null;

            if (isResponseJson(response)) {
                try {
                    data = await response.json();
                } catch (error) {
                    handleError(error.message, action);
                    return;
                }
            } else if (isResponseFileDownload(response)) {
                data = {
                    blob: await response.blob(),
                    fileName: getFileName(response),
                };
            } else if (isResponseText(response)) {
                try {
                    data = {
                        responseMessage: await response.text(),
                    };
                } catch (error) {
                    handleError(error.message, action);
                    return;
                }
            } else if (isResponseNoContent(response)) {
                // Do nothing
            } else {
                handleHttpError(response, action);
                return;
            }

            // TODO: Maybe we can work with http API directly without this?
            if (responseActionType) {
                dispatch({
                    type: responseActionType,
                    data: data,
                    passThroughData: passThroughData,
                    actionTypes: actionTypes,
                });
            }

            if (actionTypes && actionTypes.response) {
                dispatch({
                    type: actionTypes.response,
                    data: data,
                    passThroughData: passThroughData,
                    actionTypes: actionTypes,
                });
            }
        } else {
            handleHttpError(response, action);
        }
    }

    async function handleUploadStart(action) {
        const { actionTypes, passThroughData } = action;
        actionTypes?.start &&
            dispatch({
                type: actionTypes.start,
                passThroughData: passThroughData,
                actionTypes: actionTypes,
            });
    }

    async function handleUploadProgress(progress, action) {
        const { actionTypes, passThroughData } = action;
        actionTypes?.progress &&
            dispatch({
                type: actionTypes.progress,
                data: progress,
                passThroughData: passThroughData,
                actionTypes: actionTypes,
            });
    }

    async function handleHttpError(response, action) {
        let message = "";

        if (isResponseJson(response)) {
            try {
                const json = await response.json();
                message = json.responseMessage ?? json.title;
            } catch (error) {
                message = error.message;
            }
        } else {
            const text = await response.text();
            message = text ? text : `${response.status} (${response.statusText})`;
        }

        handleError(message, action);
    }

    async function handleNetworkError(error, action) {
        const message = error.message;

        handleError(message, action);
    }

    async function handleError(message, action) {
        const { url, query, body, actionTypes, skipErrorReport, passThroughData } = action;
        const state = getState();
        let name = null;

        if (actionTypes) {
            const { error } = actionTypes;
            name = error;

            error &&
                dispatch({
                    type: error,
                    message: message,
                    passThroughData: passThroughData,
                });
        }

        if (!skipErrorReport) {
            // Report error
            var errorData = createHttpError({
                name,
                message,
                body,
                url: url + getQueryString(query),
                state,
            });

            dispatch(reportError(errorData));
        }
    }

    function isTokenExpired(response) {
        let isExpired = false;

        if (response.status === 401) {
            const header = response.headers.get("www-authenticate");
            isExpired = Boolean(header && header.includes("invalid_token"));
        }

        return isExpired;
    }

    function needToRefreshToken(response) {
        const user = getState().vdsmUser;

        // Do not try to refresh token if user needs to
        // set up 2FA. If user needs to set up 2FA,
        // he gets blocked if system tries to refresh
        // the token
        if (!user || isQrCode(user)) {
            return false;
        }

        return tokenExpired(user);
    }

    function getFileName(response) {
        let fileName = null;
        const header = response.headers.get("content-disposition");

        if (header && header.includes("filename=")) {
            fileName = header.split("filename=")[1].split(";")[0];
        }

        return fileName;
    }

    function getAuthorizedHeaders() {
        const { accessToken } = getState().user;

        return {
            ...headers,
            Authorization: `Basic ${accessToken}`,
        };
    }

    function getVdsmAuthorizedHeaders() {
        const user = getState().vdsmUser;
        let accessToken = null;

        if (user) {
            accessToken = user.accessToken;
        }

        return {
            ...headers,
            Authorization: `Bearer ${accessToken}`,
        };
    }

    function getQueryString(queryParams) {
        const keys = Object.keys(queryParams ?? {});

        if (!keys.length) {
            return "";
        }

        return "?" + queryString.stringify(queryParams);
    }
}
