// BrokerHub related actions

export const API_HUB_CONNECT = "API_HUB_CONNECT";
export const API_HUB_DISCONNECT = "API_HUB_DISCONNECT";
export const API_HUB_BROADCAST_CLEAR_RESOURCE = "API_HUB_BROADCAST_CLEAR_RESOURCE";

export const HUB_EVENT_SEND = "HUB_EVENT_SEND";
export const HUB_EVENT_RECEIVE = "HUB_EVENT_RECEIVE";
export const HUB_DATASET_REFRESH = "HUB_DATASET_REFRESH";
export const HUB_DATASET_REFRESH_STATUS = "HUB_DATASET_REFRESH_STATUS";

export const USER_DISCONNECTED = "USER_DISCONNECTED";

// Calculation related actions

// Single calculation
export const RUN_CALCULATION_START = "RUN_CALCULATION_START";
export const RUN_CALCULATION_STARTED = "RUN_CALCULATION_STARTED";
export const RUN_CALCULATION_TERMINATE = "RUN_CALCULATION_TERMINATE";
export const RUN_CALCULATION_FAILED = "RUN_CALCULATION_FAILED";
export const RUN_CALCULATION_COMPLETE = "RUN_CALCULATION_COMPLETE";
export const RUN_CALCULATION_ACK = "RUN_CALCULATION_ACK";

// Multiple calculation
export const RUN_CALCULATIONS_START = "RUN_CALCULATIONS_START";

// Client related actions

export const CLIENT_SELECT = "CLIENT_SELECT"; // keeps ID of selected client

// CRUD related actions

export const API_GET_AUTHORIZED = "API_GET_AUTHORIZED";
export const API_POST_AUTHORIZED = "API_POST_AUTHORIZED";

export const API_CRUD_CREATE_PROGRESS = "API_CRUD_CREATE_PROGRESS";
export const API_CRUD_CREATE_START = "API_CRUD_CREATE_START";
export const API_CRUD_DELETE_ITEM = "API_CRUD_DELETE_ITEM";

// Download file related actions

export const DOWNLOAD_FILE_START = "DOWNLOAD_FILE_START";
export const DOWNLOAD_FILE_STARTED = "DOWNLOAD_FILE_STARTED";
export const DOWNLOAD_FILE_PROGRESS = "DOWNLOAD_FILE_PROGRESS";
export const DOWNLOAD_FILE_COMPLETE = "DOWNLOAD_FILE_COMPLETE";
export const DOWNLOAD_FILE_ERROR = "DOWNLOAD_FILE_ERROR";

// Login related actions

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_RESPONSE = "LOGIN_RESPONSE";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const API_LOGIN = "API_LOGIN";

// Model data import related actions

export const MODEL_DATA_IMPORT_STARTED = "MODEL_DATA_IMPORT_STARTED";
export const MODEL_DATA_IMPORT_COMPLETE = "MODEL_DATA_IMPORT_COMPLETE";
export const MODEL_DATA_IMPORT_ACK = "MODEL_DATA_IMPORT_ACK";

// Module related actions

export const SELECT_MODULE = "SELECT_MODULE";

export const RUN_MODULE_START = "RUN_MODULE_START";
export const RUN_MODULE_STARTED = "RUN_MODULE_STARTED";
export const RUN_MODULE_FAILED = "RUN_MODULE_FAILED";
export const RUN_MODULE_COMPLETE = "RUN_MODULE_COMPLETE";

export const PAUSE_MODULE_START = "PAUSE_MODULE_START";
export const PAUSE_MODULE_STARTED = "PAUSE_MODULE_STARTED";
export const PAUSE_MODULE_FAILED = "PAUSE_MODULE_FAILED";
export const PAUSE_MODULE_COMPLETE = "PAUSE_MODULE_COMPLETE";

export const RUN_ALL_MODULES_START = "RUN_ALL_MODULES_START";
export const RUN_ALL_MODULES_STARTED = "RUN_ALL_MODULES_STARTED";

// Projections related actions

export const SELECT_PROJECTION = "SELECT_PROJECTION";

export const SET_PROJECTIONS_VIEW_ID = "SET_PROJECTIONS_VIEW_ID";

export const OPEN_AVOIDED_COSTS = "OPEN_AVOIDED_COSTS";
export const CLOSE_AVOIDED_COSTS = "CLOSE_AVOIDED_COSTS";
export const SET_AVOIDED_COSTS_VIEW_ID = "SET_AVOIDED_COSTS_VIEW_ID";

export const MODEL_ACK_CHANGE = "MODEL_ACK_CHANGE";
export const STANDALONE_REPORT_ACK_CHANGE = "STANDALONE_REPORT_ACK_CHANGE"; // TODO: Need to implement. Look at ProjectInputs

// User related actions

export const USER_SAVE = "USER_SAVE";

export const API_RIGHTS_REQUEST = "API_RIGHTS_REQUEST";
export const API_RIGHTS_RESPONSE = "API_RIGHTS_RESPONSE";

//
// VisionDSM actions
//

// CRUD related actions

export const API_GET = "API_GET";
export const API_VDSM_GET_AUTHORIZED = "API_VDSM_GET_AUTHORIZED";
export const API_POST = "API_POST";
export const API_VDSM_POST_AUTHORIZED = "API_VDSM_POST_AUTHORIZED";
export const API_PUT = "API_PUT";
export const API_PUT_AUTHORIZED = "API_PUT_AUTHORIZED";
export const API_DELETE = "API_DELETE";
export const API_DELETE_AUTHORIZED = "API_DELETE_AUTHORIZED";

export const API_CRUD_READ_LIST_REQUEST = "API_CRUD_READ_LIST_REQUEST";
export const API_CRUD_READ_LIST_SUCCESS = "API_CRUD_READ_LIST_SUCCESS";
export const API_CRUD_READ_LIST_ERROR = "API_CRUD_READ_LIST_ERROR";
export const API_CRUD_OPTIMISTIC_UPDATE_ITEM = "API_CRUD_OPTIMISTIC_UPDATE_ITEM";
export const API_CRUD_READ_REQUEST = "API_CRUD_READ_REQUEST";
export const API_CRUD_READ_SUCCESS = "API_CRUD_READ_SUCCESS";
export const API_CRUD_READ_ERROR = "API_CRUD_READ_ERROR";
export const API_CRUD_CREATE_REQUEST = "API_CRUD_CREATE_REQUEST";
export const API_CRUD_CREATE_SUCCESS = "API_CRUD_CREATE_SUCCESS";
export const API_CRUD_CREATE_ERROR = "API_CRUD_CREATE_ERROR";
export const API_CRUD_UPDATE_REQUEST = "API_CRUD_UPDATE_REQUEST";
export const API_CRUD_UPDATE_SUCCESS = "API_CRUD_UPDATE_SUCCESS";
export const API_CRUD_UPDATE_ERROR = "API_CRUD_UPDATE_ERROR";
export const API_CRUD_DELETE_REQUEST = "API_CRUD_DELETE_REQUEST";
export const API_CRUD_DELETE_SUCCESS = "API_CRUD_DELETE_SUCCESS";
export const API_CRUD_DELETE_ERROR = "API_CRUD_DELETE_ERROR";
export const API_CRUD_NEED_UPDATE = "API_CRUD_NEED_UPDATE";
export const API_CRUD_WAIT_READ = "API_CRUD_WAIT_READ";

// Form related actions

export const FORM_GET_REQUEST = "FORM_GET_REQUEST";
export const FORM_GET_SUCCESS = "FORM_GET_SUCCESS";
export const FORM_GET_ERROR = "FORM_GET_ERROR";
export const FORM_SUBMIT_REQUEST = "FORM_SUBMIT_REQUEST";
export const FORM_SUBMIT_SUCCESS = "FORM_SUBMIT_SUCCESS";
export const FORM_SUBMIT_ERROR = "FORM_SUBMIT_ERROR";
export const FORM_CLEAR = "FORM_CLEAR";
export const FORM_MESSAGE_CLEAR = "FORM_MESSAGE_CLEAR";
export const FORM_SET_DATA = "FORM_SET_DATA";

export const APP_FORM_INIT = "APP_FORM_INIT";
export const APP_FORM_DESTROY = "APP_FORM_DESTROY";
export const APP_FORM_UPDATE = "APP_FORM_UPDATE";
export const APP_FORM_UPDATE_SCHEMA = "APP_FORM_UPDATE_SCHEMA";
export const APP_FORM_UPDATE_UI_SCHEMA = "APP_FORM_UPDATE_UI_SCHEMA";
export const APP_FORM_UPDATE_RULES = "APP_FORM_UPDATE_RULES";
export const APP_FORM_UPDATE_INITIAL_VALUES = "APP_FORM_UPDATE_INITIAL_VALUES";
export const APP_FORM_SELECT_ELEMENT = "APP_FORM_SELECT_ELEMENT";
export const APP_FORM_SET_FORM_DATA = "APP_FORM_SET_FORM_DATA";
export const APP_FORM_SET_VALIDATION_ERRORS = "APP_FORM_SET_VALIDATION_ERRORS";

// Heartbeat related actions

export const HEARTBEAT_STOP = "HEARTBEAT_STOP";

// Login related actions

export const LOGIN_RESET = "LOGIN_RESET";
export const VDSM_LOGIN_REQUEST = "VDSM_LOGIN_REQUEST";
export const VDSM_LOGIN_RESPONSE = "VDSM_LOGIN_RESPONSE";
export const VDSM_LOGIN_ERROR = "VDSM_LOGIN_ERROR";

export const LOGIN_REFRESH_REQUEST = "LOGIN_REFRESH_REQUEST";
export const LOGIN_REFRESH_RESPONSE = "LOGIN_REFRESH_RESPONSE";
export const LOGIN_REFRESH_ERROR = "LOGIN_REFRESH_ERROR";
export const LOGIN_ADD_REPEAT_ACTION = "LOGIN_ADD_REPEAT_ACTION";
export const LOGIN_CLEAR_REPEAT_ACTIONS = "LOGIN_CLEAR_REPEAT_ACTIONS";

export const API_LOGIN_REFRESH = "API_LOGIN_REFRESH";
export const API_LOGIN_REFRESH_RESPONSE = "API_LOGIN_REFRESH_RESPONSE";
export const API_LOGIN_REFRESH_ERROR_RESPONSE = "API_LOGIN_REFRESH_ERROR_RESPONSE";

export const API_VDSM_LOGIN = "API_VDSM_LOGIN";
export const API_LOGIN_RESPONSE = "API_LOGIN_RESPONSE";

// Logout related actions

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_RESPONSE = "LOGOUT_RESPONSE";
export const LOGOUT_ERROR = "LOGOUT_ERROR";

export const API_LOGOUT = "API_LOGOUT";
export const API_LOGOUT_RESPONSE = "API_LOGOUT_RESPONSE";

// Modal related actions

export const MODAL_OPEN = "MODAL_OPEN";
export const MODAL_CLOSE = "MODAL_CLOSE";

// PowerBI refresh related actions

export const POWERBI_DATASET_REFRESH_REQUESTED = "POWERBI_DATASET_REFRESH_REQUESTED";
export const POWERBI_DATASET_REFRESH_RECEIVED = "POWERBI_DATASET_REFRESH_RECEIVED";
export const POWERBI_DATASET_REFRESH_ERROR = "POWERBI_DATASET_REFRESH_ERROR";

export const POWERBI_DATASET_REFRESH_STATUS_REQUESTED = "POWERBI_DATASET_REFRESH_STATUS_REQUESTED";
export const POWERBI_DATASET_REFRESH_STATUS_RECEIVED = "POWERBI_DATASET_REFRESH_STATUS_RECEIVED";
export const POWERBI_DATASET_REFRESH_STATUS_ERROR = "POWERBI_DATASET_REFRESH_STATUS_ERROR";

// Side Navigation related actions

export const SIDENAV_OPEN = "SIDENAV_OPEN";
export const SIDENAV_CLOSE = "SIDENAV_CLOSE";
export const SIDENAV_CHANGE = "SIDENAV_CHANGE";

// Sign on credentials related actions

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_RECEIVE = "RESET_PASSWORD_RECEIVE";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const RESET_PASSWORD_ERROR_CLEAR = "RESET_PASSWORD_ERROR_CLEAR";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_RESPONSE = "FORGOT_PASSWORD_RESPONSE";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";
export const CHANGE_PASSWORD_ERROR_CLEAR = "CHANGE_PASSWORD_ERROR_CLEAR";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_RESPONSE = "UPDATE_PASSWORD_RESPONSE";
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR";
export const UPDATE_PASSWORD_ERROR_CLEAR = "UPDATE_PASSWORD_ERROR_CLEAR";

export const API_RESET_PASSWORD = "API_RESET_PASSWORD";
export const API_RESET_PASSWORD_RESPONSE = "API_RESET_PASSWORD_RESPONSE";

export const API_FORGOT_PASSWORD = "API_FORGOT_PASSWORD";
export const API_FORGOT_PASSWORD_RESPONSE = "API_FORGOT_PASSWORD_RESPONSE";

export const API_UPDATE_PASSWORD = "API_UPDATE_PASSWORD";
export const API_UPDATE_PASSWORD_RESPONSE = "API_UPDATE_PASSWORD_RESPONSE";

// System related actions

export const SYSTEM_STATUS_REQUEST = "SYSTEM_STATUS_REQUEST";
export const SYSTEM_STATUS_RECEIVE = "SYSTEM_STATUS_RECEIVE";
export const SYSTEM_STATUS_ERROR = "SYSTEM_STATUS_ERROR";

export const APP_VERSION_CHANGED = "APP_VERSION_CHANGED";
export const SET_SERVER_TIMEZONE_OFFSET = "SET_SERVER_TIMEZONE_OFFSET";

// User related actions

export const VDSM_USER_SAVE = "VDSM_USER_SAVE";
export const USER_DELETE = "USER_DELETE";

export const USER_PROFILE_READ_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_READ_SUCCESS = "USER_PROFILE_READ_SUCCESS";
export const USER_PROFILE_READ_ERROR = "USER_PROFILE_ERROR";
export const USER_PROFILE_UPDATE_REQUEST = "USER_PROFILE_UPDATE_REQUEST";
export const USER_PROFILE_UPDATE_SUCCESS = "USER_PROFILE_UPDATE_SUCCESS";
export const USER_PROFILE_UPDATE_ERROR = "USER_PROFILE_UPDATE_ERROR";

// Window related actions

export const WINDOW_CONTAINER_ADD = "WINDOW_CONTAINER_ADD";
export const WINDOW_CONTAINER_REMOVE = "WINDOW_CONTAINER_REMOVE";
export const WINDOW_ADD = "WINDOW_ADD";
export const WINDOW_UPDATE = "WINDOW_UPDATE";
export const WINDOW_REMOVE = "WINDOW_REMOVE";
export const WINDOW_ACTIVATE = "WINDOW_ACTIVATE";
