import { isEmpty, isNil } from "lodash";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { RUN_CALCULATIONS_START, RUN_ALL_MODULES_START } from "store/actionTypes";

import { useProcessesInProgress } from "store/processes/useProcessStatus";

import {
    createCombination,
    updateCombination,
    useCombinationList,
} from "store/resources/actions/projectInput/projectInputCombinationActions";
import { useProjectTerritories } from "store/resources/actions/territory/territoryActions";
import { useProjectFuels } from "store/resources/actions/fuel/fuelActions";
import { useModelInputsProgress } from "store/resources/actions/modelInputs/modelInputsProgressActions";
import { refreshPowerBIDataset } from "store/powerBI/refreshAction/actions";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import Model from "./Model";

import Button from "components/ui/ButtonNew";
import ComponentWithDropdown from "components/ui/Dropdown/ComponentWithDropdown";
import CustomList from "components/ui/List/CustomList";
import Icon from "components/ui/IconNew";
import IconLoading from "components/ui/Icons/IconLoading";
import IconMore from "components/ui/Icons/IconMore";
import TextEdit from "components/ui/Input/TextEdit";
import NothingFoundBlock from "components/ui/NothingFoundBlock";
import { StaticProgressBar } from "components/ui/ProgressBar";
import Tooltip from "components/ui/Tooltip";

import { openModalDialogPublishCombinationWarning } from "layouts/Modal/ProjectDashboardModal/utils";
import { openModalDialogDeleteCombination } from "layouts/Modal/ModelDashboardModal/utils";

import { useCalculationResultProjectLog } from "utils/useCalculationResult";
import { combinedModelDatasetId, measureLevelDatasetId } from "utils/constants";
import { toLocaleDateTime } from "utils/dateTime";
import { isNullOrWhitespace } from "utils/string";
import { hasAnyOfPermissions } from "utils/user";
import { USER_ACTIONS } from "utils/user/defines";
import { openWindowCombinedModelReport, openWindowMeasureLevelReport, openWindowSingleModelReportComparison } from "utils/window";

import { DropdownItemType } from "components/ui/Dropdown/types";
import { SelectedModels, ModelsProps } from "pages/ManageProject/ProjectionsContent/ProjectDashboard/ModelsPanel/types";
import { ProjectInput } from "store/resources/actions/projectInput/types";
import { ComponentModel } from "store/resources/actions/componentModel/types";

import "./style.scss";

const HEADERS = {
    name: "MODEL",
    modelStatus: "MODEL STATUS",
    territory: "TERRITORY",
    fuel: "FUEL",
    sector: "SECTOR",
    lastCalculatedOn: "LAST CALCULATION",
    required: "REQUIRED",
    calculationStatus: "CALCULATION STATUS",
    results: {
        label: "",
        sortable: false,
    },
};

const ACTIVE_HEADERS = {
    all: {
        label: "ALL",
        sortable: false,
    },
    ...HEADERS,
};

const COMPARE_HEADERS = {
    checkbox: {
        label: "",
        sortable: false,
    },
    ...HEADERS,
};

const COMBINATION_HEADERS = {
    bookmarked: {
        label: "",
        sortable: false,
    },
    combinationName: "NAME",
    actions: {
        label: "",
        sortable: false,
    },
};

const Models = memo((props: ModelsProps) => {
    const {
        viewIndex,
        project,
        isLoadingModels,
        allModels,
        selectToCombine,
        setSelectToCombine,
        selectToRun,
        setSelectToRun,
        selectToCompare,
        setSelectToCompare,
    } = props;

    const { idClient, idProject, projectName } = project;

    const dispatch = useDispatch();

    // Resources

    const [projectTerritories = []] = useProjectTerritories({ idProject });
    const [projectFuels = []] = useProjectFuels({ idProject });

    const [modelCombinations = [], isLoadingModelCombinations] = useCombinationList({ idProject });

    const [calculationResults] = useCalculationResultProjectLog({ idProject });

    const processesInProgress = useProcessesInProgress({ idProject });

    const [inputsProgress, isLoadingInputsProgress] = useModelInputsProgress({ idProject });

    // States

    const [selectedModels, setSelectedModels] = useState<SelectedModels>({});

    const [combinationName, setCombinationName] = useState("");

    const [combining, setCombining] = useState(false);

    // Refs

    const loadingRef = useRef<any>([]); // loading action ref (used when re-running models)

    // Variables and useMemo

    const inputsProgressPercentage = inputsProgress?.percentage;
    const inputsProgressCompleted = inputsProgress?.completed;
    const inputsProgressTotal = inputsProgress?.total;

    let headers = HEADERS;

    if (selectToCombine || selectToRun) {
        headers = ACTIVE_HEADERS;
    } else if (selectToCompare) {
        headers = COMPARE_HEADERS;
    }

    // useEffects

    useEffect(() => {
        if (processesInProgress.runCalculations && processesInProgress.runCalculations !== false) {
            loadingRef.current = []; // once calculations are running, set loading action ref back to initial value
        }
    }, [idProject, processesInProgress.runCalculations, dispatch]);

    // Event handlers

    const onRenameCombinationClick = useCallback(
        (idCombination, combinationName) => {
            dispatch(
                // @ts-ignore
                updateCombination({
                    idCombination,
                    idProject,
                    combinationName,
                    action: "Renaming combination",
                })
            );
        },
        [dispatch, idProject]
    );

    const onPublishCombinationClick = useCallback(
        (idCombination, combinationName, bookmarked) => {
            if (bookmarked === false) {
                dispatch(
                    openModalDialogPublishCombinationWarning({
                        onAccept: () => {
                            dispatch(
                                // @ts-ignore
                                updateCombination({
                                    idProject,
                                    idCombination,
                                    combinationName,
                                    bookmarked: true,
                                    action: "Publishing reports for client access",
                                })
                            );
                        },
                    })
                );
            } else {
                dispatch(
                    // @ts-ignore
                    updateCombination({
                        idProject,
                        idCombination,
                        combinationName,
                        bookmarked: false,
                        action: "Unpublishing reports for client access",
                    })
                );
            }
        },
        [idProject, dispatch]
    );

    const onCombineClick = useCallback(() => {
        setCombining(true);
    }, []);

    const onCombineCancelClick = useCallback(() => {
        setSelectedModels({});
        setSelectToCombine(false);
        setCombining(false);
        setCombinationName("");
    }, [setSelectToCombine]);

    const onRunModelsCancelClick = useCallback(() => {
        setSelectedModels({});
        setSelectToRun(false);
    }, [setSelectToRun]);

    const onCompareModelsCancelClick = useCallback(() => {
        setSelectedModels({});
        setSelectToCompare(false);
    }, [setSelectToCompare]);

    const onSaveCombinationClick = useCallback(() => {
        const modelInput = Object.keys(selectedModels).map((idInputLog) => parseInt(idInputLog));

        dispatch(
            createCombination({
                idProject,
                combinationName,
                modelInput,
                action: "Creating a combination",
                onComplete: () => {
                    if (combinedModelDatasetId) {
                        dispatch(refreshPowerBIDataset({ datasetId: combinedModelDatasetId }));
                    }

                    if (measureLevelDatasetId) {
                        dispatch(refreshPowerBIDataset({ datasetId: measureLevelDatasetId }));
                    }
                },
            })
        );

        onCombineCancelClick();
    }, [idProject, selectedModels, combinationName, onCombineCancelClick, dispatch]);

    const onRunModelsClick = useCallback(() => {
        const allSelectedModelIds = Object.keys(selectedModels);
        const selectedComponentModelIds = Object.keys(selectedModels).filter((idInputLog) => selectedModels[idInputLog].isComponentModel);
        const selectedLegacyModelIds = Object.keys(selectedModels).filter((idInputLog) => !selectedModels[idInputLog].isComponentModel);

        loadingRef.current = [...allSelectedModelIds];

        if (!isEmpty(selectedComponentModelIds)) {
            selectedComponentModelIds.forEach((idInputLog) => {
                dispatch({
                    type: RUN_ALL_MODULES_START,
                    payload: {
                        idProject,
                        idModel: selectedModels[idInputLog].componentModelParams?.idModel,
                        runList: selectedModels[idInputLog].componentModelParams?.runList,
                    },
                });
            });
        }

        if (!isEmpty(selectedLegacyModelIds)) {
            const legacyModels: {
                idInputLog: number;
                includePotential: boolean;
            }[] = []; // store Model IDs and results type here

            selectedLegacyModelIds.forEach((idInputLog) => {
                const model = {
                    idInputLog: parseInt(idInputLog),
                    includePotential: selectedModels[idInputLog].includePotential || false,
                };

                legacyModels.push(model);
            });

            dispatch({
                type: RUN_CALCULATIONS_START,
                idProject,
                models: legacyModels,
            });
        }

        onRunModelsCancelClick();
    }, [idProject, selectedModels, onRunModelsCancelClick, dispatch]);

    const onCompareModelsClick = useCallback(() => {
        let leftViewProps, rightViewProps;
        const selectedModelIds = Object.keys(selectedModels);

        for (const key of selectedModelIds) {
            // Only populate array with Models that has calculation results
            const modelItems = allModels.reduce((modelItems: DropdownItemType[], model: ProjectInput | ComponentModel) => {
                if (isNil(model.idInputLog) || calculationResults[model.idInputLog] === undefined) {
                    return modelItems;
                }

                const { results, finished } = calculationResults[model.idInputLog];

                modelItems.push({
                    label: `${model.name} - ${toLocaleDateTime(`${finished}Z`)}`,
                    value: model.idInputLog,
                    itemProps: {
                        idInputLog: model.idInputLog,
                        filterEntityId: results,
                    },
                });

                return modelItems;
            }, []);

            const { results, finished } = calculationResults[key];

            const model = allModels.find((model: ProjectInput | ComponentModel) => model.idInputLog === parseInt(key));

            if (selectedModels[key].primary === true) {
                leftViewProps = {
                    filterEntityId: results,
                    idInputLog: parseInt(key),
                    modelName: model.name,
                    finished: toLocaleDateTime(`${finished}Z`),
                    items: modelItems,
                };
            } else if (selectedModels[key].primary === false) {
                rightViewProps = {
                    filterEntityId: results,
                    idInputLog: parseInt(key),
                    modelName: model.name,
                    finished: toLocaleDateTime(`${finished}Z`),
                    items: modelItems,
                };
            }
        }

        leftViewProps && rightViewProps && dispatch(openWindowSingleModelReportComparison({ project, leftViewProps, rightViewProps }));

        onCompareModelsCancelClick();
    }, [project, selectedModels, allModels, calculationResults, onCompareModelsCancelClick, dispatch]);

    const onCombinedModelReportSelect = useCallback(
        (idCombination, combinationName) => {
            dispatch(
                openWindowCombinedModelReport({
                    idClient,
                    idProject,
                    filterEntityId: idCombination,
                    projectName,
                    combinationName,
                })
            );
        },
        [idClient, idProject, projectName, dispatch]
    );

    const onMeasureLevelReportSelect = useCallback(
        (idCombination, combinationName) => {
            dispatch(
                openWindowMeasureLevelReport({
                    idClient,
                    idProject,
                    filterEntityId: idCombination,
                    projectName,
                    combinationName,
                })
            );
        },
        [idClient, idProject, projectName, dispatch]
    );

    const onCombinationDeleteSelect = useCallback(
        (idCombination, combinationName) => {
            dispatch(
                openModalDialogDeleteCombination({
                    idProject,
                    idCombination,
                    combinationName,
                })
            );
        },
        [idProject, dispatch]
    );

    // Render functions

    const renderCombination = useCallback(
        (item) => {
            const actionItems = [
                {
                    label: "Combined Model Report",
                    icon: "powerbi-reports",
                    onSelect: () => onCombinedModelReportSelect(item.idCombination, item.combinationName),
                },
                {
                    label: "Measure-level Report",
                    icon: "document-table-empty",
                    onSelect: () => onMeasureLevelReportSelect(item.idCombination, item.combinationName),
                },
                {
                    label: "Delete Combination",
                    icon: "delete-trash-empty",
                    onSelect: () => onCombinationDeleteSelect(item.idCombination, item.combinationName),
                },
            ];

            return (
                <div key={item.idCombination} className="list-item-row">
                    <div className="item-value column-bookmarked">
                        <Button
                            variant="tertiary"
                            icon={item.bookmarked ? "files-bookmark_a_f" : "files-bookmark_b_s"}
                            iconSize="sm"
                            isDisabled={combining}
                            padding="xs"
                            onClick={() => onPublishCombinationClick(item.idCombination, item.combinationName, item.bookmarked)}
                            title={item.bookmarked ? "Unpublish reports" : "Publish reports"}
                        />
                    </div>
                    <div className="item-value column-combination-name">
                        {hasAnyOfPermissions([USER_ACTIONS.MODELS_COMBINE]) ? (
                            <TextEdit
                                title="Edit name"
                                disabled={combining}
                                value={item.combinationName}
                                onApply={(combinationName) => onRenameCombinationClick(item.idCombination, combinationName)}
                            />
                        ) : (
                            item.combinationName
                        )}
                    </div>
                    <div className="item-value column-actions">
                        {item.idCombination > 0 && (
                            <ComponentWithDropdown component={<IconMore clickable />} items={actionItems} withoutShevron />
                        )}
                    </div>
                </div>
            );
        },
        [
            combining,
            onRenameCombinationClick,
            onPublishCombinationClick,
            onCombinedModelReportSelect,
            onMeasureLevelReportSelect,
            onCombinationDeleteSelect,
        ]
    );

    const renderNewCombination = useCallback(() => {
        return (
            <div className="list-item-row fill-width">
                <div className="item-value column-combination-name">
                    <TextEdit
                        title="Edit name"
                        edit
                        value={combinationName}
                        onApply={(combinationName) => setCombinationName(combinationName)}
                    />
                </div>
            </div>
        );
    }, [combinationName]);

    // Main render

    const modelProps = {
        territories: projectTerritories,
        fuels: projectFuels,
        calculationResults,
        processesInProgress,

        viewIndex,
        project,
        selectedModels,
        selectToCombine,
        selectToRun,
        selectToCompare,
        combining,
        loading: loadingRef.current,

        setSelectedModels,
    };

    return (
        <>
            {isLoadingModels || isLoadingModelCombinations || isLoadingInputsProgress ? (
                <IconLoading />
            ) : (
                <div className="models">
                    {isEmpty(allModels) ? (
                        <NothingFoundBlock dataTestId="no-models" icon="ui-chart_multiple_b_s" title="There are no models" />
                    ) : (
                        <>
                            <StaticProgressBar
                                className="margin-bottom models__progress-bar"
                                percentage={inputsProgressPercentage}
                                topDescription="Completion of the Project based on required Models and their inputs."
                                bottomDescription={`${inputsProgressPercentage}% - ${inputsProgressCompleted} of ${inputsProgressTotal} Completed`}
                            />
                            {selectToCombine && (
                                <div className="flex-row align-center justify-space-between margin-bottom">
                                    <div className="flex-row align-center">
                                        <div className="models__action-title">Combine Models</div>
                                        <Tooltip
                                            className="margin-left-small"
                                            customIcon={<Icon icon="text-info_italic__more__details__information_about_b_f" clickable />}
                                        >
                                            Select at least one model to be able to combine. You can only combine models that have results.
                                        </Tooltip>
                                    </div>
                                    {!combining && (
                                        <IdsButtonGroup spaceBetween="lg">
                                            <Button
                                                variant="primary"
                                                padding="lg"
                                                isDisabled={Object.keys(selectedModels).length === 0}
                                                onClick={onCombineClick}
                                            >
                                                Combine
                                            </Button>
                                            <Button variant="secondary" padding="lg" onClick={onCombineCancelClick}>
                                                Cancel
                                            </Button>
                                        </IdsButtonGroup>
                                    )}
                                </div>
                            )}
                            {selectToRun && (
                                <div className="flex-row align-center justify-space-between margin-bottom">
                                    <div className="flex-row align-center">
                                        <div className="models__action-title">Run Models</div>
                                        <Tooltip
                                            className="margin-left-small"
                                            customIcon={<Icon icon="text-info_italic__more__details__information_about_b_f" clickable />}
                                        >
                                            Select at least one model to be able to run calculations. You can only run calculations for
                                            models that don't have any errors.
                                        </Tooltip>
                                    </div>
                                    <IdsButtonGroup spaceBetween="lg">
                                        <Button
                                            variant="primary"
                                            padding="lg"
                                            isDisabled={Object.keys(selectedModels).length === 0}
                                            onClick={onRunModelsClick}
                                        >
                                            <>{Object.keys(selectedModels).length > 1 ? "Run Models" : "Run Model"}</>
                                        </Button>
                                        <Button variant="secondary" padding="lg" onClick={onRunModelsCancelClick}>
                                            Cancel
                                        </Button>
                                    </IdsButtonGroup>
                                </div>
                            )}
                            {selectToCompare && (
                                <div className="flex-row align-center justify-space-between margin-bottom">
                                    <div className="flex-row align-center">
                                        <div className="models__compare-title">Compare Models</div>
                                        <Tooltip
                                            className="margin-left-small"
                                            customIcon={<Icon icon="text-info_italic__more__details__information_about_b_f" clickable />}
                                        >
                                            You can only compare models with results.
                                        </Tooltip>
                                    </div>
                                    <IdsButtonGroup spaceBetween="lg">
                                        <Button
                                            variant="primary"
                                            padding="lg"
                                            isDisabled={Object.keys(selectedModels).length < 2}
                                            onClick={onCompareModelsClick}
                                        >
                                            Compare Models
                                        </Button>
                                        <Button variant="secondary" padding="lg" onClick={onCompareModelsCancelClick}>
                                            Cancel
                                        </Button>
                                    </IdsButtonGroup>
                                </div>
                            )}
                            <CustomList
                                // @ts-ignore - remove when CustomList is refactored to TypeScript
                                dataTestId="model-list"
                                className="model-list"
                                headers={headers}
                                items={allModels}
                                component={Model}
                                componentProps={modelProps}
                                limit={8}
                                lastColumnToRight
                                sortable
                            />
                        </>
                    )}
                    {!isEmpty(allModels) && hasAnyOfPermissions([USER_ACTIONS.MODELS_COMBINE]) && (
                        <div className="models__combined-models-container">
                            <div className="content-header margin-bottom">Model combinations for reporting</div>
                            {isEmpty(modelCombinations) && !combining ? (
                                <NothingFoundBlock icon="ui-chart_multiple_b_s" title="There are no combinations" />
                            ) : (
                                <CustomList
                                    // @ts-ignore
                                    className="combined-models-list"
                                    headers={COMBINATION_HEADERS}
                                    items={modelCombinations}
                                    limit={8}
                                    lastColumnToRight
                                    sortable
                                    renderItem={renderCombination}
                                    renderBeforeBody={combining ? renderNewCombination : undefined}
                                />
                            )}
                        </div>
                    )}
                    {combining && !isNullOrWhitespace(combinationName) && (
                        <div className="models__update-action-container">
                            <IdsButtonGroup position="right" spaceBetween="lg">
                                <Button variant="primary" padding="lg" onClick={onSaveCombinationClick}>
                                    Update Models
                                </Button>
                                <Button variant="secondary" padding="lg" onClick={onCombineCancelClick}>
                                    Cancel
                                </Button>
                            </IdsButtonGroup>
                        </div>
                    )}
                </div>
            )}
        </>
    );
});

export default Models;
