import { runAllModulesResourceName, runModuleResourceName } from "store/configureResources";

import { createResource } from "store/resources/actions/createResource";

import { CreateRunModuleParams, CreateRunAllModulesParams } from "./types";

export const createRunModule =
    ({ moduleId, onSuccess, onError }: CreateRunModuleParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            createResource({
                resourceName: runModuleResourceName,
                resourceId: runModuleResourceName,
                path: {
                    moduleId,
                },
                onSuccess,
                onError,
            })
        );
    };

export const createRunAllModules =
    ({ idModel, runList, onSuccess, onError }: CreateRunAllModulesParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            createResource({
                resourceName: runAllModulesResourceName,
                resourceId: runAllModulesResourceName,
                path: {
                    idModel,
                },
                body: {
                    runList,
                },
                onSuccess,
                onError,
            })
        );
    };
