import { availableVDSMResources } from "./configureVDSMResources";

// KEYS

// Avoided Costs keys

export const avoidedCostsTemplateValidationKey = "avoided-costs-validation";
export const avoidedCostsTemplateSectorsKey = "avoided-costs-sectors";

// Fuels keys

export const fuelsClientResourceKey = "fuels-client";

// Model (Measure) Inputs keys

export const allMeasureInputsResourceKey = "measure-inputs-all";

// Territories keys

export const territoriesAllResourceKey = "territories-all";
export const territoriesClientResourceKey = "territories-client";
export const territoriesProjectResourceKey = "territories-project";

// RESOURCE NAMES

// Action Statistics resource names

export const actionStatisticsResourceName = "action-stats";

// Active Views resource names

export const activeViewsModelResourceName = "active-views-model";
export const activeViewsCustomReportResourceName = "active-views-custom-report";
export const activeViewsAvoidedCostsResourceName = "active-views-avoided-costs";

// Avoided Costs resource names

export const avoidedCostsTemplateResourceName = "avoided-costs";

// Bookmarks resource names

export const bookmarkedProjectsResourceName = "bookmarked-projects";
// export const bookmarkedModelsResourceName = "bookmarked-models";
// export const bookmarkedModelCombinationsResourceName = "bookmarked-model-combinations";

// Calculation resource names

export const calculationsResourceName = "calculations"; // Note: Legacy Model calculations
export const calculationsLogResourceName = "calculations-log";
export const calculationsPreconditionsResourceName = "calculations-preconditions-v2";
export const stopCalculationResourceName = "calculation-stop";

// Calculation Workflow resource names

export const modulesResourceName = "modules";
export const moduleStudyCasesResourceName = "module-study-cases-list";
export const moduleVariablesResourceName = "module-variables-list";
export const moduleInputsResourceName = "module-inputs-list";
export const moduleAllRunsResourceName = "module-all-runs-list";
export const moduleLastRunResourceName = "module-last-run";
export const runModuleResourceName = "run-module";
export const runAllModulesResourceName = "run-all-modules";
export const pauseModuleResourceName = "pause-module";
export const passFailModulesResourceName = "pass-fail-modules";
export const moduleRunParamsResourceName = "module-run-update-params";

// Clients resource names

export const clientsResourceName = "clients";

// Fuel resource names

export const fuelsResourceName = "fuels";

// Help Tips resource names

export const helpTipsResourceName = "help-tips";

// Model (Project Input) resource names

export const projectInputCombinationsResourceName = "project-input-combinations";
export const projectInputCombinationsCheckResourceName = "project-input-combinations-check";
export const projectInputsResourceName = "project-inputs";
export const projectInputsInactiveResourceName = "project-inputs-inactive";
export const projectInputsNamesResourceName = "project-inputs-names";
export const projectInputsDuplicateResourceName = "project-inputs-duplicate";
export const projectInputValidationsResourceName = "project-input-validations";
export const projectInputValidationsDetailsResourceName = "project-input-validations-details";
export const projectInputValidationsColumnsResourceName = "project-input-validations-columns";

export const componentModelResourceName = "component-model";
export const componentModelListResourceName = "component-model-list";
export const componentModelTemplateListResourceName = "component-model-template-list";
export const componentModelCloneResourceName = "component-models-clone";

// Model Data Import resource names

export const publicDataImportResourceName = "dataimport-public";

export const modelDataImportV3ResourceName = "dataimport-model-v3";
export const componentModelDataImportV3ResourceName = "dataimport-component-model-v3";
export const modelDataImportStatusResourceName = "dataimport-model-status";

// Model Inputs (Measure Inputs) resource names

export const avoidedCostsSetupResourceName = "avoided-costs-setup";
export const modelInputsResourceName = "model-inputs"; // Note: Used to update Model Inputs (PUT)
export const modelInputsProgressResourceName = "model-inputs-progress";
export const modelInputNotesResourceName = "model-input-notes";
// export const modelInputNoteCommentsResourceName = "model-input-note-comments";

export const modelInputsV2ResourceName = "model-inputs-v2"; // Note: Used for getting Model Inputs (GET)

// Project resource names

export const projectsResourceName = "projects";
export const projectNamesResourceName = "project-names";
export const bookmarkedProjectStatusesResourceName = "project-statuses-bookmarked";

// Project Data Import resource names

export const projectDataImportResourceName = "dataimport-project";

// Project Files resource names

export const projectFilesResourceName = "project-files";
export const projectFileResourceName = "project-file";
export const projectFileAccessResourceName = "project-file-access";
export const projectFileTypesResourceName = "project-file-types";
export const projectFilesUploadedResourceName = "project-files-uploaded";
export const projectFinalReportExistsResourceName = "project-final-report-exists";

// Market Profile resource names

export const marketProfileResourceName = "market-profile";

// Power BI resource names

export const powerBIResourceName = "powerBI";
export const powerBIPublicResourceName = "powerBIPublic";
export const refreshPowerBIResourceName = "powerBIRefresh";

// Results resource names

export const singleResultResourceName = "single-result";
export const multipleResultsResourceName = "multiple-results";
export const resultTablesResourceName = "result-tables";

// Scenario Log resource names

export const scenarioLogResourceName = "scenario-log";
export const modelLogNotesResourceName = "model-log-notes";
// export const modelLogNoteCommentsResourceName = "model-log-note-comments";

// Standalone Report resource names

export const standaloneReportsResourceName = "standalone-reports";
export const standaloneReportsFilesResourceName = "standalone-reports-files";

// Study Cases (Study Goals) resource names

export const studyCaseProjectResourceName = "study-case-project";
export const studyCaseModelResourceName = "study-case-model";

// Territories resource names

export const territoriesResourceName = "territories";

// User resource names

export const usersResourceName = "users";
export const teamMembersResourceName = "team-members";
export const userRoleResourceName = "user-role";

// Variables resource names

export const variablesResourceName = "variables";
export const labelVariablesResourceName = "variables-label";

export const availableResources = [
    {
        resourceName: actionStatisticsResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}action-stats`,
    },
    {
        resourceName: activeViewsModelResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}active-views/model/{idInputLog}`,
    },
    {
        resourceName: activeViewsCustomReportResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}active-views/custom-reports/{idReport}`,
    },
    {
        resourceName: activeViewsAvoidedCostsResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}active-views/avoided-costs/{idInputLog}`,
    },
    {
        resourceName: avoidedCostsTemplateResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}avoided-costs/{idInputLog}`,
    },
    {
        resourceName: bookmarkedProjectsResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}bookmarks/projects/{idProject}`,
    },
    // {
    //     resourceName: bookmarkedModelsResourceName,
    //     resourceUrl: `${process.env.REACT_APP_API_BASE_URL}bookmarks/models/{idInputLog}`,
    // },
    // {
    //     resourceName: bookmarkedModelCombinationsResourceName,
    //     resourceUrl: `${process.env.REACT_APP_API_BASE_URL}bookmarks/model-combinations/{idCombination}`,
    // },
    {
        resourceName: calculationsResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}calculations`,
    },
    {
        resourceName: calculationsLogResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}calculations/log`,
    },
    {
        resourceName: calculationsPreconditionsResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}calculations/preconditionsv2`,
    },
    {
        resourceName: stopCalculationResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}calculations/{idCalculation}`,
    },
    {
        resourceName: modulesResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}calculations/modules/{entityId}`,
    },
    {
        resourceName: moduleStudyCasesResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}calculations/moduletype/studycases`,
    },
    {
        resourceName: moduleVariablesResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}calculations/variables/{moduleId}`,
    },
    {
        resourceName: moduleInputsResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}calculations/inputs/{moduleId}`,
    },
    {
        resourceName: moduleAllRunsResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}calculations/module/{moduleId}/allruns`,
    },
    {
        resourceName: moduleLastRunResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}calculations/module/{moduleId}/lastrun`,
    },
    {
        resourceName: runModuleResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}calculations/{moduleId}/run`,
    },
    {
        resourceName: runAllModulesResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}calculations/{idModel}/runPending`,
    },
    {
        resourceName: pauseModuleResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}calculations/{moduleId}/run/{resultsId}`,
    },
    {
        resourceName: passFailModulesResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}passfailmodel`,
    },
    {
        resourceName: componentModelCloneResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}clonemodels`,
    },
    {
        resourceName: moduleRunParamsResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}calculations/run/params/{resultsId}`,
    },
    {
        resourceName: clientsResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}clients`,
    },
    {
        resourceName: fuelsResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}fuels`,
    },
    {
        resourceName: helpTipsResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}help-tips/{idHelpTip}`,
    },
    {
        resourceName: projectInputCombinationsResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}input-combinations/{idCombination}`,
    },
    {
        resourceName: projectInputCombinationsCheckResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}input-combinations/check/{idInputLog}`,
    },
    {
        resourceName: projectInputsResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}inputs/{idInputLog}`,
    },
    {
        resourceName: projectInputsInactiveResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}inputs-inactive`,
    },
    {
        resourceName: projectInputsNamesResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}inputs-names/{idProject}`,
    },
    {
        resourceName: projectInputsDuplicateResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}inputs-duplicate`,
    },
    {
        resourceName: projectInputValidationsResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}input-validations`,
    },
    {
        resourceName: projectInputValidationsDetailsResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}input-validations/details`,
    },
    {
        resourceName: projectInputValidationsColumnsResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}input-validations/columns`,
    },
    {
        resourceName: componentModelResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}model`,
    },
    {
        resourceName: componentModelListResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}{projectId}/models`,
    },
    {
        resourceName: componentModelTemplateListResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}models`,
    },
    {
        resourceName: modelDataImportStatusResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}dataimport`,
    },
    {
        resourceName: modelDataImportV3ResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}dataimport-v3`,
    },
    {
        resourceName: componentModelDataImportV3ResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}modeldataimport-v3`,
    },
    {
        resourceName: publicDataImportResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}publicdataimport`,
    },
    {
        resourceName: avoidedCostsSetupResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}measure-inputs/ac-setup/{idInputLog}`,
    },
    {
        resourceName: modelInputsResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}measure-inputs/{idDataImportLog}`,
    },
    {
        resourceName: modelInputsProgressResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}measure-inputs/progress/{idProject}`,
    },
    {
        resourceName: modelInputsV2ResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}measure-inputs-v2/{idDataImportLog}`,
    },
    {
        resourceName: modelInputNotesResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}inputs-notes/{idInputNote}`,
    },
    // {
    //     resourceName: modelInputNoteCommentsResourceName,
    //     resourceUrl: `${process.env.REACT_APP_API_BASE_URL}inputs-comments/{idInputNoteComment}`,
    // },
    {
        resourceName: projectsResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}projects/{idProject}`,
    },
    {
        resourceName: bookmarkedProjectStatusesResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}projects/statuses/bookmarked`,
    },
    {
        resourceName: projectNamesResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}projects-names/{idClient}`,
    },
    {
        resourceName: projectDataImportResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}project-import/{projectId}/{projectUploadId}`,
    },
    {
        resourceName: projectFilesResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}project-files/{idProject}`,
    },
    {
        resourceName: projectFileResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}project-files/file/{idProject}`,
    },
    {
        resourceName: projectFileAccessResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}project-files/file-access`,
    },
    {
        resourceName: projectFileTypesResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}project-files/file-types`,
    },
    {
        resourceName: projectFileTypesResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}project-files/file-types`,
    },
    {
        resourceName: projectFilesUploadedResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}project-files/uploaded-files/{idProject}`,
    },
    {
        resourceName: projectFinalReportExistsResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}project-files/final-report/{idProject}`,
    },
    {
        resourceName: marketProfileResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}project-upload/{projectUploadId}`,
    },
    {
        resourceName: powerBIResourceName,
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}powerbi/{reportID}`,
    },
    {
        resourceName: powerBIPublicResourceName,
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}report/{reportID}`,
    },
    {
        resourceName: refreshPowerBIResourceName,
        resourceUrl: `${process.env.REACT_APP_SYSTEM_API_BASE_URL}powerbi/refresh/{workspaceID}/{datasetID}`,
    },
    {
        resourceName: singleResultResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}resultsV2`,
    },
    {
        resourceName: multipleResultsResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}resultsV3`,
    },
    {
        resourceName: resultTablesResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}available-tables`,
    },
    {
        resourceName: scenarioLogResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}scenario-log`,
    },
    {
        resourceName: modelLogNotesResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}model-notes/{idModelNote}`,
    },
    // {
    //     resourceName: modelLogNoteCommentsResourceName,
    //     resourceUrl: `${process.env.REACT_APP_API_BASE_URL}model-comments/{idModelNoteComment}`,
    // },
    {
        resourceName: standaloneReportsResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}custom-reports/{idReport}`,
    },
    {
        resourceName: standaloneReportsFilesResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}custom-reports/files/{fileName}`,
    },
    {
        resourceName: studyCaseProjectResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}study-case/project/{idProject}`,
    },
    {
        resourceName: studyCaseModelResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}study-case/model/{idInputLog}`,
    },
    {
        resourceName: territoriesResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}territories`,
    },
    {
        resourceName: usersResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}users/{userNumber}`,
    },
    {
        resourceName: teamMembersResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}users/team-members`,
    },
    {
        resourceName: userRoleResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}users/user-role/{userName}`,
    },
    {
        resourceName: variablesResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}variables`,
    },
    {
        resourceName: labelVariablesResourceName,
        resourceUrl: `${process.env.REACT_APP_API_BASE_URL}variables/labels`,
    },
].concat(availableVDSMResources);
