import { batch } from "react-redux";

import { moduleVariablesResourceName } from "store/configureResources";

import { clearResource } from "store/resources/actions/clearResource";
import { useResource } from "store/resources/actions/useResource";
import { updateResource } from "store/resources/actions/updateResource";

import { getResourceState } from "store/utils";

import { clearCalculationPreconditions } from "store/resources/actions/calculations/calculationsPreconditionsActions";
import { clearInputValidations } from "store/resources/actions/projectInput/projectInputValidationsActions";

import { clearModules } from "./modulesActions";

import { removeObjEmptyProps } from "utils/object";

import { ModuleVariable, CommonModuleParams, UseModuleVariablesParams, UpdateModuleVariablesParams } from "./types";
import { IndexSignature } from "types/types";

const clearModuleVariables =
    ({ moduleId }: CommonModuleParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            clearResource({
                resourceName: moduleVariablesResourceName,
                key: `${moduleVariablesResourceName}-${moduleId}`,
                broadcast: true,
            })
        );
    };

// TODO: Try to use Generics to achieve multiple return types based on parameters
export const useModuleVariables = ({ moduleId, transform }: UseModuleVariablesParams) =>
    useResource({
        resourceName: moduleVariablesResourceName,
        key: `${moduleVariablesResourceName}-${moduleId}`,
        path: {
            moduleId,
        },
        transform,
    });

export const updateModuleVariables =
    ({ idProject, idModel, idInputLog, moduleId, values, action, onSuccess }: UpdateModuleVariablesParams) =>
    // @ts-ignore
    (dispatch, getState) => {
        const formItems: IndexSignature<any> = {};
        let updateItems = [];

        // Do optimistic update only on Save action
        if (action === "Save") {
            const state = getState();

            const moduleVariables = getResourceState<ModuleVariable>(state, moduleVariablesResourceName, { moduleId });
            const modifiedValues = removeObjEmptyProps(values);

            for (const key of Object.keys(modifiedValues)) {
                if (Array.isArray(modifiedValues[key])) {
                    formItems[key] = JSON.stringify(modifiedValues[key]);
                } else {
                    formItems[key] = modifiedValues[key];
                }
            }

            updateItems = moduleVariables.map((mv) => ({ ...mv, value: modifiedValues[mv.name] || mv.value }));
        }

        dispatch(
            updateResource({
                resourceName: moduleVariablesResourceName,
                key: `${moduleVariablesResourceName}-${moduleId}`,
                path: {
                    moduleId,
                },
                query: {
                    action,
                },
                body: formItems,
                optimisticUpdate:
                    updateItems.length > 0
                        ? {
                              value: updateItems,
                          }
                        : undefined,
                onSuccess: () => {
                    batch(() => {
                        dispatch(clearModuleVariables({ moduleId }));
                        dispatch(clearModules({ idProject, idModel }));
                        dispatch(clearCalculationPreconditions({ idProject }));
                        dispatch(clearInputValidations({ idInputLog }));
                    });

                    onSuccess?.();
                },
                onError: () => {
                    batch(() => {
                        dispatch(clearModuleVariables({ moduleId }));
                        dispatch(clearModules({ idProject, idModel }));
                    });
                },
            })
        );
    };
