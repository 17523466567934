import cn from "classnames";
import { isEmpty } from "lodash";
import { memo, useCallback } from "react";

import { useComponents } from "store/resources/actions/calculationWorkflow/moduleRunsActions";

import { IdsTabPanel } from "@emergn-infinity/ids-react";

import Icon from "components/ui/IconNew";
import IconLoading from "components/ui/Icons/IconLoading";

import { CalculationProps } from "./types";
import { ComponentStatus } from "store/resources/actions/calculationWorkflow/types";

import "./style.scss";

const Calculations = memo(({ moduleId, id, ariaLabelledBy, isActive }: CalculationProps) => {
    const [components, isLoadingComponents] = useComponents({ moduleId });

    const classNames = cn("flex-column fill-height with-scroll remove-background calculations-tab", { "is-hidden": !isActive });

    const getBranchClassNames = useCallback((isOuter: boolean, status: ComponentStatus) => {
        const className = isOuter ? "outer-branch" : "inner-branch";

        return cn(`calculations-tab__${className}`, { "branch-done": status === "DONE" });
    }, []);

    const getStepIconClassNames = useCallback((status: ComponentStatus) => {
        return cn("calculations-tab__step-icon", {
            "step-icon-in-progress": status === "IN_PROGRESS",
            "step-icon-error": status === "ERROR",
            "step-icon-done": status === "DONE",
        });
    }, []);

    const getStepIcon = useCallback((status: ComponentStatus) => {
        switch (status) {
            case "ERROR":
                return "text-alert__error_b_f";

            case "DONE":
                return "ui-checked__check_circle_b_f";

            default:
                return "figures-circle__full_moon_b_s";
        }
    }, []);

    return (
        <IdsTabPanel customClasses={classNames} idValue={id} slot="panel" ariaLabelledBy={ariaLabelledBy}>
            {isLoadingComponents ? (
                <IconLoading />
            ) : (
                !isEmpty(components) && (
                    <div>
                        {Object.keys(components).map((key, outerIndex) => (
                            <div key={`component-${components[key].state}`} className="calculations-tab__components-container">
                                {outerIndex < Object.keys(components).length - 1 && (
                                    <div className={getBranchClassNames(true, components[key].status)} />
                                )}
                                <div className="flex-row align-center">
                                    <Icon
                                        className={getStepIconClassNames(components[key].status)}
                                        icon={getStepIcon(components[key].status)}
                                        size="md"
                                    />
                                    <div className="calculations-tab__name">{components[key].state}</div>
                                </div>
                                <div className="calculations-tab__steps-container">
                                    {components[key].steps.map((st, innerIndex) => (
                                        <div key={`step-${st.step}-${innerIndex}`} className="flex-row align-center calculations-tab__step">
                                            {innerIndex < components[key].steps.length - 1 && (
                                                <div className={getBranchClassNames(false, st.status)} />
                                            )}
                                            <Icon className={getStepIconClassNames(st.status)} icon={getStepIcon(st.status)} size="md" />
                                            <div className="calculations-tab__name">{st.step}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )
            )}
        </IdsTabPanel>
    );
});

export default Calculations;
