import { isEmpty } from "lodash";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { useModules } from "store/resources/actions/calculationWorkflow/modulesActions";
import { getAllModuleRuns } from "store/resources/actions/calculationWorkflow/moduleRunsActions";
import { useResultTables } from "store/resources/actions/results/resultsActions";

import { downloadMultipleModelResults } from "store/downloadFile/actions";

import { IdsButtonGroup } from "@emergn-infinity/ids-react";

import Button from "components/ui/ButtonNew";
import Dropdown from "components/ui/Dropdown";
import Checkbox from "components/ui/Input/Checkbox";
import IconLoading from "components/ui/Icons/IconLoading";
import Label from "components/ui/Label";
import ActionLabel from "components/ui/Label/ActionLabel";
import Separator from "components/ui/Separator";

import { d } from "utils/date";

import { DownloadDataProps } from "layouts/Modal/ModelDashboardModal/types";
import { IndexSignature } from "types/types";
import { ModuleRun } from "store/resources/actions/calculationWorkflow/types";

const initialSelectedTables = ["Equipment Purchases", "Equipment Results", "Equipment Stock"];

const DownloadData = memo((props: DownloadDataProps) => {
    const { idProject, idModel, onCancel } = props;

    const dispatch = useDispatch();

    const [allModuleRuns, setAllModuleRuns] = useState<IndexSignature<ModuleRun[]>>({});

    const [selectedResults, setSelectedResults] = useState<string[]>([]);
    const [selectedTables, setSelectedTables] = useState<string[]>(initialSelectedTables);
    const [selectedVersions, setSelectedVersions] = useState<IndexSignature<string>>({});

    const [isDownloading, setIsDownloading] = useState(false);

    const [modules, isLoadingModules] = useModules({ idProject, idModel });
    const [resultTables, isLoadingResultTables] = useResultTables();

    const isDownloadDisabled = selectedResults.length === 0 || selectedTables.length === 0 || isDownloading;

    useEffect(() => {
        if (!isEmpty(modules) && !isLoadingModules) {
            const getValidModuleRuns = async () => {
                const newAllModuleRuns = {};

                for (const m of modules) {
                    const moduleRuns = await getAllModuleRuns({ moduleId: m.moduleId });

                    const validRuns = moduleRuns.filter((moduleRun) => moduleRun.status === "DONE");

                    newAllModuleRuns[`${m.moduleOrder} ${m.studyCase}`] = validRuns;
                }

                setAllModuleRuns(newAllModuleRuns);
            };

            getValidModuleRuns();
        }
    }, [modules, isLoadingModules]);

    const resultItems = useMemo(() => {
        const resultItems = [];

        if (!isEmpty(modules) && !isLoadingModules && !isEmpty(allModuleRuns)) {
            modules.forEach((m) => {
                const label = `${m.moduleOrder} ${m.studyCase}`;

                const latestRun = allModuleRuns[label]?.find((moduleRun) => moduleRun.status === "DONE");

                if (latestRun) {
                    resultItems.push({
                        label: label,
                        value: `${latestRun.resultsId}`,
                    });
                }
            });
        }

        return resultItems;
    }, [modules, isLoadingModules, allModuleRuns]);

    const selectedResultItems = resultItems.filter((resultItem) => selectedResults.includes(resultItem.value));

    const onChange = (value: string | number | readonly string[], name: string, key?: string) => {
        switch (name) {
            case "selectedResults":
                setSelectedResults(value as string[]);

                break;

            case "selectedTables":
                {
                    const _value = value as string;
                    let newSelectedTables = [...selectedTables];

                    if (newSelectedTables.find((table) => table === _value)) {
                        newSelectedTables = newSelectedTables.filter((table) => table !== _value);
                    } else {
                        newSelectedTables.push(_value);
                    }

                    setSelectedTables(newSelectedTables);
                }

                break;

            case "selectedVersions":
                {
                    const newSelectedVersions = { ...selectedVersions };

                    newSelectedVersions[key] = value as string;

                    setSelectedVersions(newSelectedVersions);
                }

                break;

            default:
        }
    };

    const onSelectAllClick = useCallback(
        (deselect: boolean) => {
            if (deselect) {
                setSelectedTables([]);
            } else {
                setSelectedTables(resultTables);
            }
        },
        [resultTables]
    );

    const onDownloadClick = () => {
        setIsDownloading(true);

        let idResultsLogs = [...selectedResults];

        if (!isEmpty(selectedVersions)) {
            idResultsLogs = [];

            selectedResultItems.forEach((resultItem) => {
                if (selectedVersions?.[resultItem.label] !== undefined) {
                    idResultsLogs.push(selectedVersions[resultItem.label]);
                } else {
                    idResultsLogs.push(resultItem.value);
                }
            });
        }

        dispatch(
            downloadMultipleModelResults({
                idResultsLogs,
                includedTables: selectedTables,
                onComplete: onCancel,
            })
        );
    };

    return (
        <>
            <div className="flex-column modal-padding">
                {isLoadingModules || isLoadingResultTables || isEmpty(allModuleRuns) ? (
                    <div className="flex-column align-center">
                        <IconLoading />
                    </div>
                ) : (
                    <>
                        <Dropdown
                            label="Download modules"
                            placeholder="Select from the list"
                            value={selectedResults}
                            items={resultItems}
                            withFilter
                            multiple
                            singleLine
                            required
                            onChange={(value) => onChange(value, "selectedResults")}
                        />
                        <Separator line />
                        <div className="flex-column gap-2">
                            <div className="flex-row align-center justify-space-between">
                                <Label required>Download tables</Label>
                                <ActionLabel onClick={() => onSelectAllClick(resultTables?.length === selectedTables.length)}>
                                    {resultTables?.length === selectedTables.length ? "Deselect all" : "Select all"}
                                </ActionLabel>
                            </div>
                            <div className="flex-row flex-wrap gap-2">
                                {resultTables?.map((table) => (
                                    <div key={`result-table-${table}`} className="one-third">
                                        <Checkbox
                                            id={table}
                                            name={table}
                                            label={table}
                                            checked={selectedTables.includes(table)}
                                            onChange={() => onChange(table, "selectedTables")}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        {selectedResultItems.length > 0 && (
                            <>
                                <Separator line />
                                <div className="flex-column gap-3">
                                    {selectedResultItems.map((resultItem) => (
                                        <Dropdown
                                            key={`version-${resultItem.value}`}
                                            label={`Download version (${resultItem.label})`}
                                            value={selectedVersions[resultItem.label] ?? resultItem.value}
                                            items={allModuleRuns[resultItem.label].map((moduleRun, index) => ({
                                                label: `${moduleRun.scenarioName ? moduleRun.scenarioName + " - " : ""}${d.format(
                                                    new Date(moduleRun.finished + "Z")
                                                )} ${index === 0 ? "(latest)" : ""}`,
                                                value: `${moduleRun.resultsId}`,
                                            }))}
                                            onChange={(value) => onChange(value, "selectedVersions", resultItem.label)}
                                        />
                                    ))}
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
            <IdsButtonGroup customClasses="modal-actions" position="right" spaceBetween="lg">
                <Button
                    variant="primary"
                    iconLeft={isDownloading ? undefined : "arrows-align_bottom_b_a"}
                    iconSize="sm"
                    padding="lg"
                    isDisabled={isDownloadDisabled}
                    onClick={onDownloadClick}
                >
                    <span>{isDownloading ? <IconLoading /> : "Download"}</span>
                </Button>
                <Button variant="secondary" padding="lg" onClick={onCancel}>
                    Cancel
                </Button>
            </IdsButtonGroup>
        </>
    );
});

export default DownloadData;
