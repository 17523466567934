import { pauseModuleResourceName } from "store/configureResources";

import { updateResource } from "store/resources/actions/updateResource";

import { CreatePauseModuleParams } from "./types";

export const updatePauseModule =
    ({ moduleId, resultsId, onSuccess, onError }: CreatePauseModuleParams) =>
    // @ts-ignore
    (dispatch) => {
        dispatch(
            updateResource({
                resourceName: pauseModuleResourceName,
                path: {
                    moduleId,
                    resultsId,
                },
                onSuccess,
                onError,
            })
        );
    };
